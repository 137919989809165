import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Flex,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast
} from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import apiGateway from '../../../../../shared/services/apiGateway';
import Form from '../../../../../components/Form';
import Select from '../../../../../components/Form/Select';
import Button from '../../../../../components/Button';
import { HTTP } from '../../../../../shared/constants';


interface IProps {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
}

const ModalFlorists: React.FC<IProps> = ({ isOpen, onClose, orderId }) => {
    const [florists, setFlorists] = useState([]);

    const formRef = useRef<FormHandles>(null);

    const toast = useToast();
    const navigate = useNavigate();

    const handleConfirm = useCallback(() => {
        const data = formRef.current.getData();
        if(data?.florist_id) {
            apiGateway
                .put(`/orders/${orderId}/florist`, {
                    florist_id: data.florist_id
                })
                .then(response => {
                    const { status } = response;
                    if(status === HTTP.STATUS.SUCCESS) {
                        toast({
                            title: 'Florista Selecionada',
                            description: '',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });

                        onClose();
                    }
                });
        }
    }, [orderId]);

    useEffect(() => {
        apiGateway
            .get('/users/florists')
            .then(response => {
                setFlorists(response.data);
            });
       
    }, []);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="500">Escolher Florista</ModalHeader>
                    <ModalCloseButton />

                    <Flex width="100%" px="24px" flexDirection="column">
                        <Flex
                            width="100%"
                            flexDirection="row"
                            mt="16px"
                            alignItems="center"
                            fontWeight="500"
                        >
                            <Form ref={formRef as any} onSubmit={() => null}>
                                <Flex width="100%" fontSize="12px">
                                    {florists.length <= 0 && (
                                        <Flex width="100%" 
                                            flexDirection="column" 
                                            fontSize="18px" 
                                            alignItems="center"
                                            color="gray.400"
                                        >
                                            <Text>Ainda não foi registrado nenhuma</Text>
                                            <Text>Registre agora clicando abaixo</Text>
                                            <Button
                                                mt="24px"
                                                backgroundColor="purple.500"
                                                width="100%"
                                                fontSize="16px"
                                                height="40px"
                                                mr="16px"
                                                onClick={() => navigate('/settings/users/register')}
                                            >
                                                Registrar Florista
                                            </Button>
                                        </Flex>
                                    )}
                                    {florists.length > 0 && (
                                        <Select name='florist_id' placeholder="Selecione uma florista"> 
                                            {florists.map((florist) => (
                                                <option key={florist.id} value={florist.id}>
                                                    {florist.name || florist.email}
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                </Flex>
                            </Form>
                        </Flex>
                    </Flex>

                    <ModalFooter fontWeight="500" color="white">
                        {florists.length > 0 && (
                            <Flex width="100%">
                                <Button
                                    borderRadius="2px"
                                    fontSize="14px"
                                    backgroundColor="red.500"
                                    mr={3}
                                    onClick={onClose}
                                    py="8px"
                                    _hover={{
                                        backgroundColor: 'red.500'
                                    }}
                                >
                            Cancelar
                                </Button>
                        
                                <Button
                                    borderRadius="2px"
                                    fontSize="14px"
                                    backgroundColor={'green.500'}
                                    py="8px"
                                    onClick={() => handleConfirm()}
                                >
                            Confirmar
                                </Button>
                            </Flex>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalFlorists;
