import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Box, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Select from '../../../components/Form/Select';
import TextArea from '../../../components/Form/TextArea';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import apiGateway from '../../../shared/services/apiGateway';

const CitiesEditPage: React.FC = () => {
    const { id } = useParams() as any;
    const [city, setCity] = useState<any>(null);
    const formRef = useRef<FormHandles>();
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const navigate = useNavigate();
    const toast = useToast();
    
    const handleChangeState = useCallback((stateOption: any) => {
        const stateValue = stateOption.value;
        if (stateValue) {
            apiGateway.get(`/location/states/${stateValue}/cities`).then(response => {
                setCities(response.data);
            });
        }
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.put(`/cities/${id}`, {
                    name: formData.name,
                    description: formData.description,
                    meta_description: formData.meta_description,
                    keywords: formData.keywords,
                    state_id: formData.state_id
                });

                if (response.status === HTTP.STATUS.SUCCESS) {
                    FormHelper.onSuccess(
                        'Operação Realizada',
                        'Cidade atualizada com sucesso',
                        toast
                    );
                    FormHelper.reset(formRef as any);
                    navigate('/settings/cities');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef as any, error, toast);
                }
            }
        },
        [navigate, id, toast]
    );

    useEffect(() => {
        apiGateway.get('/location/states').then(response => {
            setStates(response.data);
        });
        apiGateway.get(`/cities/${id}`).then(response => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                apiGateway
                    .get(`/location/states/${response.data.state_id}/cities`)
                    .then(response2 => {
                        setCities(response2.data);

                        setCity(response.data);
                    });
            }
        });
    }, [id]);

    return (
        city && (
            <Form
                ref={formRef as any}
                onSubmit={handleSubmit}
                autoComplete="off"
                style={{
                    width: '100%'
                }}
            >
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontWeight="500"
                    fontSize="14px"
                >
                    <Text color="purple.500" fontSize="20px" mt="16px">
            Informações Básicas
                    </Text>
                    <Flex
                        minWidth="100%"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        mt="8px"
                    >
                        <Box width="48%">
                            <Select
                                name="state_id"
                                label="Selecione o estado"
                                isRequired
                                size="sm"
                                onChange={e => handleChangeState(e.currentTarget)}
                                defaultValue={city.state_id}
                            >
                                <option value="">Selecione um estado</option>
                                {states.map((state: any) => (
                                    <option key={state.id} value={state.id}>
                                        {state.name}
                                    </option>
                                ))}
                            </Select>
                        </Box>

                        <Box width="48%">
                            <Select
                                name="name"
                                label="Selecione a cidade"
                                isRequired
                                size="sm"
                                defaultValue={city.name}
                            >
                                <option value="">Selecione uma cidade</option>
                                {cities.map((item: any) => (
                                    <option key={item.id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                    </Flex>

                    <Flex width="100%" flexDirection="column">
                        <Box>
                            <TextArea
                                name="description"
                                label="Descriçao"
                                defaultValue={city.description}
                            />
                        </Box>

                        <Box>
                            <TextArea
                                name="meta_description"
                                label="Meta Description"
                                defaultValue={city.meta_description}
                            />
                        </Box>

                        <Box>
                            <TextArea
                                name="keywords"
                                label="Keywords"
                                defaultValue={city.keywords}
                            />
                        </Box>
                    </Flex>

                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
            Editar
                    </Button>
                </Flex>
            </Form>
        )
    );
};

export default CitiesEditPage;
