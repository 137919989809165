import React, { useCallback, useState } from 'react';
import { FiCopy } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    useToast
} from '@chakra-ui/core';

import checkGreen from '../../../../../../assets/images/check-success.png';
import Button from '../../../../../../components/Button';
import { PAY3_LINK_URL } from '../../../../../../shared/constants';

const FinishModal: React.FC<any> = ({ isOpen, onClose, order }) => {
    const toast = useToast();

    const navigate = useNavigate();

    const handleCopy = useCallback(async (type, value) => {
        const store: any = localStorage.getItem('@eflorista:store');

        if (type === 'LINK') {
            await navigator.clipboard.writeText(`${PAY3_LINK_URL}/${store}/${value}`);
        }

        if (type === 'DEPOSIT') {
            await navigator.clipboard.writeText(value);
        }

        toast({
            title: type === 'LINK' ? 'Link Copiado' : 'Chave Copiada',
            description: 'Copiado efetuada',
            status: 'success',
            duration: 4000
        });
    }, []);

    return (
        <Flex>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Flex
                            flexDirection={'column'}
                            px="24px"
                            py="16px"
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Image width="96px" height="96px" src={checkGreen} mb="24px" />
                            <Text fontWeight={'500'} fontSize="20px" py="8px" mb="24px">
                Pedido Finalizado
                            </Text>

                            <Flex>
                                <Flex flexDirection={'column'}>
                                    <Text width="200px">N do Pedido:</Text>
                                    {order.payment_method.type === 'DEPOSIT' && (
                                        <Text width="200px">Chaves Pix:</Text>
                                    )}

                                    {order.payment_method.type === 'LINK' && (
                                        <Text width="200px">Link de Pagamento:</Text>
                                    )}
                                </Flex>

                                <Flex flexDirection={'column'}>
                                    <Text>{order.code}</Text>

                                    {order.payment_method.type === 'DEPOSIT' && (
                                        <Flex flexDirection={'column'}>
                                            <Flex
                                                cursor={'pointer'}
                                                onClick={() =>
                                                    handleCopy('DEPOSIT', order.payment_method.key_pix_1)
                                                }
                                                alignItems="center"
                                            >
                                                <Text textDecoration="underline" mr="8px">
                                                    {order.payment_method.key_pix_1}
                                                </Text>
                                                <FiCopy />
                                            </Flex>
                                            {!!order.payment_method.key_pix_2 === true && (
                                                <Flex
                                                    cursor={'pointer'}
                                                    onClick={() =>
                                                        handleCopy(
                                                            'DEPOSIT',
                                                            order.payment_method.key_pix_2
                                                        )
                                                    }
                                                    alignItems="center"
                                                >
                                                    <Text textDecoration="underline" mr="8px">
                                                        {order.payment_method.key_pix_2}
                                                    </Text>
                                                    <FiCopy />
                                                </Flex>
                                            )}
                                        </Flex>
                                    )}

                                    {order.payment_method.type === 'LINK' && (
                                        <Flex
                                            cursor={'pointer'}
                                            onClick={() => handleCopy('LINK', order?.link?.hash)}
                                            alignItems="center"
                                        >
                                            <Text
                                                mr="8px"
                                                textDecoration="underline"
                                            >{`${order?.link?.hash}`}</Text>
                                            <FiCopy />
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Flex width={'100%'} justifyContent="flex-end">
                            <Button
                                backgroundColor={'green.500'}
                                width="124px"
                                py="12px"
                                fontSize={'16px'}
                                onClick={() => navigate(0)}
                            >
                FECHAR
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default FinishModal;
