import React, { useCallback, useState } from 'react';

import { Collapse, Flex } from '@chakra-ui/core';

import TextArea from '../../../../../../components/Form/TextArea';
import CollapseCheckoutBox from '../../../../components/CollapseCheckoutBox';
import { useCashier } from '../../../../hooks/useCashier';

// import { Container } from './styles';

const ObservationSection: React.FC = () => {
    const { getStateBox, collapseBox } = useCashier();

    const [sectionStatus, setSectionStatus] = useState({
        isSuccess: false,
        isError: false
    });

    const handleChangeObs = useCallback(value => {
        if (value) {
            setSectionStatus({
                isSuccess: true,
                isError: false
            });
        } else {
            setSectionStatus({
                isSuccess: false,
                isError: false
            });
        }
    }, []);

    return (
        <Flex width={'100%'} flexDirection="column">
            <CollapseCheckoutBox
                title="Observações"
                isCollapsed={getStateBox('OBSERVATIONS')}
                onCollapse={() => collapseBox('OBSERVATIONS')}
                isComplete={sectionStatus.isSuccess}
            />

            <Collapse
                isOpen={getStateBox('OBSERVATIONS')}
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                pt="8px"
            >
                <Flex width={'100%'}>
                    <TextArea
                        name="observations"
                        label="Acrescentar observações"
                        backgroundColor="gray.500"
                        onChange={e => handleChangeObs(e.currentTarget.value)}
                    />
                </Flex>
            </Collapse>
        </Flex>
    );
};

export default ObservationSection;
