import React from 'react';

import { Flex, Text, FlexProps } from '@chakra-ui/core';

interface IProps extends FlexProps {
  isSelected?: boolean;
  text: string;
  buttonFunction: (...args: any) => any;
}

const PanelButton: React.FC<IProps> = ({
    text,
    isSelected,
    buttonFunction,
    ...rest
}) => {
    return (
        <Flex
            width="100%"
            maxWidth="80px"
            py="4px"
            px="8px"
            backgroundColor={isSelected ? 'purple.500' : 'white'}
            color={isSelected ? 'white' : 'purple.500'}
            justifyContent="center"
            fontSize="12px"
            mr="8px"
            cursor="pointer"
            border="1px solid"
            borderColor="purple.500"
            onClick={buttonFunction}
            whiteSpace="nowrap"
            {...rest}
            alignItems="center"
        >
            <Text>{text}</Text>
        </Flex>
    );
};

export default PanelButton;
