import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { FiChevronDown, FiChevronRight, FiX } from 'react-icons/fi';

import { Box, Flex, Text } from '@chakra-ui/core';

interface IProps {
  title: string;
  isCollapsed: boolean;
  isComplete?: boolean;
  isErrored?: boolean;
  onCollapse: () => void;
}
const CollapseCheckoutBox: React.FC<IProps> = ({
    title,
    isCollapsed,
    isComplete = false,
    isErrored = false,
    onCollapse
}) => {
    return (
        <Flex
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            color="purple.500"
            py="4px"
            onClick={() => onCollapse()}
            cursor="pointer"
            mb="4px"
        >
            <Flex alignItems="center">
                {isComplete && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="50%"
                        border="2px solid"
                        borderColor="green.500"
                        backgroundColor="green.500"
                        width="20px"
                        height="20px"
                    >
                        <FaCheck color="white" size={10} />
                    </Box>
                )}

                {isErrored && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="50%"
                        border="2px solid"
                        borderColor="pink.500"
                        backgroundColor="pink.500"
                        width="20px"
                        height="20px"
                    >
                        <FiX color="white" size={14} />
                    </Box>
                )}

                {!isComplete && !isErrored && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="50%"
                        borderColor="purple.500"
                        border="2px solid"
                        width="20px"
                        height="20px"
                    >
                        {isCollapsed && (
                            <Box
                                width="10px"
                                height="10px"
                                backgroundColor="purple.500"
                                borderRadius="50%"
                            />
                        )}
                    </Box>
                )}

                <Text
                    ml="20px"
                    fontWeight="500"
                    fontSize={['16px', '16px', '18px']}
                    whiteSpace="nowrap"
                >
                    {title}
                </Text>
            </Flex>

            {isCollapsed && <FiChevronDown size={20} />}
            {!isCollapsed && <FiChevronRight size={20} />}
        </Flex>
    );
};

export default CollapseCheckoutBox;
