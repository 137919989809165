import React from 'react';

import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/core';

const ModalConfirmation: React.FC<any> = ({
    isOpen,
    onClose,
    actionFunction
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text fontWeight="500">Deseja arquivar as vendas selecionadas?</Text>
                </ModalHeader>
                <ModalBody>
                    <Text>
            Ao arquivar, todas as vendas selecionadas desapareceram da sua
            listagem.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        <Text fontWeight="500">Cancelar</Text>
                    </Button>
                    <Button variantColor="red" onClick={() => actionFunction()}>
                        <Text fontWeight="500">Confirmar</Text>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalConfirmation;
