import React, { useCallback, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { MdNotificationsNone } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import {
    Flex,
    Box,
    Heading,
    Avatar,
    Text,
    Collapse,
    PseudoBox
} from '@chakra-ui/core';

import { useLayout } from '../../layouts/default';
import { useAuth } from '../../shared/hooks/useAuth';
import CircleBadge from '../CircleBadge';

const Header: React.FC = () => {
    const { isExpandedMenu, title } = useLayout();

    const [showDropdown, setShowDropdown] = useState(false);

    const { signOut } = useAuth();
    const navigate = useNavigate();

    const handleLogout = useCallback(() => {
        signOut();
        navigate('/login');
    }, [history, signOut]);

    return (
        <Flex width="100%">
            <Flex
                width={`calc(100% - ${isExpandedMenu ? 250 : 80}px)`}
                backgroundColor="white"
                borderBottom="1px solid"
                borderColor="gray.500"
                px="80px"
                py="8px"
                alignItems="center"
                justifyContent="space-between"
                position="fixed"
                zIndex={10000000}
            >
                <Box color="purple.500">
                    <Heading fontSize="24px">{title}</Heading>
                </Box>
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    maxWidth="80px"
                >
                    <CircleBadge num={0}>
                        <MdNotificationsNone size={24} />
                    </CircleBadge>

                    <Flex
                        flexDirection="column"
                        position="relative"
                        onMouseUp={() => setShowDropdown(true)}
                        cursor="pointer"
                    >
                        <Avatar size="sm" />

                        <Collapse
                            onMouseLeave={() => setShowDropdown(false)}
                            isOpen={showDropdown}
                            display="flex"
                            p="8px"
                            position="absolute"
                            backgroundColor="white"
                            top="48px"
                            width="160px"
                            fontSize="14px"
                            flexDirection="column"
                            alignItems="center"
                            right="-200%"
                            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                            justifyContent="center"
                        >
                            <PseudoBox
                                display="flex"
                                alignItems="center"
                                _hover={{
                                    color: 'purple.500'
                                }}
                                onClick={() => handleLogout()}
                                zIndex={1000}
                            >
                                <Text mr="16px">Fazer Logout</Text>
                                <FiLogOut size={14} />
                            </PseudoBox>
                        </Collapse>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Header;
