/* eslint-disable */
// @ts-nocheck  
import React, { useRef, useEffect } from 'react';
import { FiInfo } from 'react-icons/fi';
import InputMask from 'react-input-mask';

import {
  Flex,
  Input as ChakraInput,
  Text,
  InputProps,
  Box
} from '@chakra-ui/core';
import { useField } from '@unform/core';

import InputBadgeError from '../InputBadgeError';
import { InfoTooltip } from '../InputBadgeError/styles';

interface IProps extends InputProps {
  name: string;
  label?: string;
  isRequired?: boolean;
  mask?: string;
  formatChars?: {
    [key: string]: string;
  };
  maskChar?: string;
  badgeErrorColor?: string;
  mb?: string | number;
  type?: 'default' | 'search';
  typeInput?: string;
  badge?: 'currency' | 'porcentage';
  tooltip?: boolean;
  tooltipMessage?: string;
  onEnterPress?: () => void;
}

const Input: React.FC<IProps> = ({
  name,
  label,
  isRequired = false,
  mask = '',
  formatChars,
  maskChar = '',
  badgeErrorColor = 'red',
  mb = '16px',
  badge = null,
  tooltip = false,
  tooltipMessage = '',
  typeInput = 'text',
  onEnterPress = undefined,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [error, fieldName, registerField]);

  return (
    <Flex width="100%" flexDirection="column" fontWeight="500" mb={mb}>
      {label && (
        <Flex
          width="100%"
          textAlign="center"
          mb="4px"
          justifyContent="space-between"
        >
          <Flex>
            <Text>{label}</Text>
            {isRequired && (
              <Text ml="8px" color="red.500">
                *
              </Text>
            )}
          </Flex>

          {tooltip && (
            <InfoTooltip title={tooltipMessage}>
              <FiInfo size={16} />
            </InfoTooltip>
          )}
        </Flex>
      )}

      <Flex
        width="100%"
        flexDirection="column"
        position="relative"
        flexWrap="wrap"
      >
        {badge && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="gray.700"
            width="40px"
            height="100%"
            position="absolute"
            left={badge === 'currency' ? '0' : 'none'}
            right={badge === 'porcentage' ? '0' : 'none'}
            zIndex={1000}
          >
            <Text color="white" fontSize="14px" fontWeight="500">
              {badge === 'currency' ? 'R$' : '%'}
            </Text>
          </Box>
        )}
        <InputMask
          formatChars={formatChars}
          mask={mask}
          value={rest.value}
          onChange={rest.onChange}
          onBlur={rest.onBlur}
          maskChar={maskChar}
        >  
          {(inputProps: any) => (
            <ChakraInput
                ref={inputRef}
                name={name}
                isInvalid={!!error}
                errorBorderColor="red.500"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="false"
                type={typeInput}
                onKeyDown={e => {
                    if (e.key === 'Enter' && onEnterPress) {
                    onEnterPress();
                    }
                }}
                pl={badge === 'currency' ? '48px' : '12px'}
                pr={badge === 'porcentage' ? '48px' : '12px'}
                _focus={{
                    outline: 'none'
                }}
                {...inputProps}
                {...rest}
            />
          )}
        </InputMask>
        {error && <InputBadgeError error={error} color={badgeErrorColor} />}
      </Flex>
    </Flex>
  );
};

export default Input;
