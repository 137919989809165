import React, { useCallback, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FiAward, FiTrash2 } from 'react-icons/fi';

import { Flex, PseudoBox, Box, Text, Image } from '@chakra-ui/core';

import FileUpload from '../../../../components/Form/FileUpload';

interface IProps {
  setFunction: any;
  defaultImage?: any;
}

const UploadDeliveryImage: React.FC<IProps> = ({
    setFunction,
    defaultImage = undefined
}) => {
    const [uploadedImage, setUploadedImage] = useState(defaultImage);

    const onUploadImage = useCallback(imageUploaded => {
        setUploadedImage(imageUploaded);

        setFunction(imageUploaded.url2);
    }, []);

    const onRemoveImage = useCallback(() => {
        setUploadedImage(undefined);
    }, []);

    return !uploadedImage ? (
        <Flex width="244px" height="160px">
            <FileUpload
                setUploadedImage={onUploadImage}
                url="https://app.eflorista.com.br/api/v1/pages/upload"
            />
        </Flex>
    ) : (
        <Flex
            key={uploadedImage.id}
            flexDirection="column"
            alignItems="center"
            mr="16px"
            position="relative"
        >
            <PseudoBox
                mt="8px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="200px"
                height="160px"
                _hover={{
                    cursor: 'pointer',
                    color: 'green.500'
                }}
            >
                <Flex
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    {!uploadedImage.uploaded && !uploadedImage.error && (
                        <CircularProgressbar
                            styles={{
                                root: { width: 48 },
                                path: { stroke: '#1EBD94' }
                            }}
                            strokeWidth={10}
                            value={uploadedImage.progress}
                        />
                    )}

                    {uploadedImage.uploaded && <Image src={uploadedImage.preview} />}
                </Flex>
            </PseudoBox>

            <Box
                p="4px"
                backgroundColor="pink.500"
                color="white"
                borderRadius="2px"
                position="absolute"
                top="0px"
                right="-10px"
                title="Excluir imagem"
                cursor="pointer"
                onClick={() => onRemoveImage()}
            >
                <FiTrash2 size={16} />
            </Box>
        </Flex>
    );
};

export default UploadDeliveryImage;
