import './styles/global.css';

import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RouterProvider } from 'react-router-dom';

import ThemeContainer from './contexts/theme/ThemeContainer';
import { AuthProvider } from './shared/hooks/useAuth';
import router from './routes';

const App: any = (): any => {  
    return (
        <ThemeContainer>
            <AuthProvider>
                <DndProvider backend={HTML5Backend} />
                <RouterProvider router={router} />
            </AuthProvider>
        </ThemeContainer>
    );
};

export default App;
