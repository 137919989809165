import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Divider, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import TextArea from '../../../../components/Form/TextArea';
import { useLayout } from '../../../../layouts/default';
import apiGateway from '../../../../shared/services/apiGateway';
import { HTTP } from '../../../../shared/constants';

const PaymentDepositSettings: React.FC = () => {
    const { changeTitle } = useLayout();
    const toast = useToast();
    const [depositMethod, setDepositMethod] = useState(null);
    const formRef = useRef<FormHandles>(null);
    const handleSubmit = useCallback(
        data => {
            apiGateway.put(`/settings/payment_methods/${depositMethod.id}`, {
                ...depositMethod,
                data: data.info,
                key_pix_1: data.key_pix_1,
                key_pix_2: data.key_pix_2
            }).then((response) => {
                const { status } = response;
                if(status === HTTP.STATUS.SUCCESS){
                    toast({
                        title: 'Configuração Atualizada',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
        },
        [depositMethod]
    );

    useEffect(() => {
        changeTitle('Configurarações de Depósito');
        apiGateway.get('/settings/payment_methods/DEPOSIT').then(response => {
            setDepositMethod(response.data);
        });
    }, [changeTitle]);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="white"
                        px="24px"
                    >
                        <Flex
                            width="100%"
                            py="16px"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex
                                    width="100%"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Text mr="16px" fontSize="24px" color="purple.500">
                    Dados para Recebimento do Depósito
                                    </Text>
                                </Flex>

                                <Flex width="100%">
                                    <Flex width="100%" mt="24px" justifyContent={'space-between'}>
                                        <Flex width="48%" flexDirection="column">
                                            <Flex width="100%">
                                                <TextArea
                                                    name="info"
                                                    minHeight="296px"
                                                    placeholder="
                        Exemplo: &#13; &#13;

                        Banco: Itau &#13; &#13; 
                        Agencia: 0000 &#13;
                        Conta: 0000000-0 &#13;
                        
                        Chave PIX: 000000000 &#13; &#13;

                        Obs: informe todas as contas que deseja receber transferência bancária
                      "
                                                    defaultValue={depositMethod?.data}
                                                />
                                            </Flex>

                                            <Flex width="100%">
                                                <Button
                                                    type="submit"
                                                    width="100%"
                                                    backgroundColor="purple.500"
                                                    height="48px"
                                                    mt="24px"
                                                    color="white"
                                                    _hover={{
                                                        backgroundColor: 'green.500'
                                                    }}
                                                    fontSize="24px"
                                                    fontWeight="400"
                                                    borderRadius="2px"
                                                >
                          Confirmar
                                                </Button>
                                            </Flex>
                                        </Flex>

                                        <Flex width={'48%'}>
                                            <Flex flexDirection={'column'} width="60%">
                                                <Input
                                                    label="Chave Pix 1"
                                                    name="key_pix_1"
                                                    size="sm"
                                                    defaultValue={depositMethod?.key_pix_1}
                                                />
                                                <Input
                                                    label="Chave Pix 2"
                                                    name="key_pix_2"
                                                    size="sm"
                                                    defaultValue={depositMethod?.key_pix_2}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Divider my="0px" />
                        </Flex>
                        <Divider my="0px" />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default PaymentDepositSettings;
