import React, { useCallback, useState } from 'react';

import { Collapse, Flex } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Input from '../../../../../../components/Form/Input';
import CollapseCheckoutBox from '../../../../components/CollapseCheckoutBox';
import { useCashier } from '../../../../hooks/useCashier';

interface IProps {
  formRef: React.MutableRefObject<FormHandles>;
}

const BuyerSection: React.FC<IProps> = ({ formRef }) => {
    const { collapseBox, getStateBox } = useCashier();

    const [sectionStatus, setSectionStatus] = useState({
        isSuccess: false,
        isError: false
    });

    const handleRequiredFields = useCallback(() => {
        const value1 = formRef.current.getFieldValue('buyer_name');
        const value2 = formRef.current.getFieldValue('buyer_telephone');

        if (value1 && value2) {
            formRef.current.setFieldError('buyer_name', '');
            formRef.current.setFieldError('buyer_telephone', '');

            setSectionStatus({
                isSuccess: true,
                isError: false
            });
        } else {
            setSectionStatus({
                isSuccess: false,
                isError: false
            });
        }
    }, []);

    return (
        <Flex width={'100%'} flexDirection="column">
            <CollapseCheckoutBox
                title="Dados do Comprador"
                isCollapsed={getStateBox('CUSTOMER')}
                onCollapse={() => collapseBox('CUSTOMER')}
                isComplete={sectionStatus.isSuccess}
            />

            <Collapse isOpen={getStateBox('CUSTOMER')} pt="8px">
                <Input
                    name="buyer_name"
                    backgroundColor="gray.500"
                    placeholder="Nome do Cliente *"
                    borderRadius="2px"
                    size="sm"
                    mb="8px"
                    onBlur={() => handleRequiredFields()}
                />

                <Input
                    name="buyer_telephone"
                    backgroundColor="gray.500"
                    placeholder="Telefone *"
                    borderRadius="2px"
                    size="sm"
                    mb="8px"
                    mask="99999999999999"
                    onBlur={() => handleRequiredFields()}
                />

                <Input
                    name="buyer_email"
                    backgroundColor="gray.500"
                    placeholder="E-mail"
                    borderRadius="2px"
                    size="sm"
                    mb="8px"
                />
            </Collapse>
        </Flex>
    );
};

export default BuyerSection;
