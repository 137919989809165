import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';

import { Collapse, Flex, Text } from '@chakra-ui/core';

import Button from '../../../../../../components/Button';
import Calendar from '../../../../../../components/Form/Calendar';
import Select from '../../../../../../components/Form/Select';
import CollapseCheckoutBox from '../../../../components/CollapseCheckoutBox';
import { useCashier } from '../../../../hooks/useCashier';

interface IProps {
  deliveryDate: Date;
  handleChangeDeliveryDate: any;
}

const ScheduleSection: React.FC<IProps> = ({
    deliveryDate,
    handleChangeDeliveryDate
}) => {
    const { getStateBox, collapseBox } = useCashier();
    const [showCalendar, setShowCalendar] = useState(false);

    const [sectionStatus, setSectionStatus] = useState({
        isSuccess: false,
        isError: false
    });

    const handleChangeTurn = useCallback(value => {
        if (value) {
            setSectionStatus({
                isSuccess: true,
                isError: false
            });
        } else {
            setSectionStatus({
                isSuccess: false,
                isError: false
            });
        }
    }, []);

    return (
        <Flex width={'100%'} flexDirection="column">
            <CollapseCheckoutBox
                title="Agendar Entrega"
                isCollapsed={getStateBox('SCHEDULE')}
                onCollapse={() => collapseBox('SCHEDULE')}
                isComplete={sectionStatus.isSuccess}
            />

            <Collapse
                isOpen={getStateBox('SCHEDULE')}
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                pt="8px"
            >
                <Flex width="48%">
                    <Button
                        backgroundColor="purple.500"
                        borderRadius="2px"
                        size="sm"
                        height="32px"
                        py="0px"
                        fontSize="14px"
                        display="flex"
                        alignItems="center"
                        _focus={{
                            outline: 'none'
                        }}
                        onClick={() => setShowCalendar(oldState => !oldState)}
                    >
                        <FiCalendar size={16} />
                        <Text ml="8px">{format(deliveryDate, 'dd/MM/yyyy')}</Text>
                    </Button>
                </Flex>

                <Flex width="48%">
                    <Select
                        name="delivery_hour"
                        backgroundColor="gray.500"
                        placeholder="Horário"
                        borderRadius="2px"
                        size="sm"
                        onChange={e => handleChangeTurn(e.currentTarget.value)}
                    >
                        <option>Manhã</option>
                        <option>Tarde</option>
                        <option>Noite</option>
                    </Select>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Collapse width="100%" isOpen={showCalendar} display="flex">
                        <Calendar
                            isErrored={false}
                            selectedDate={deliveryDate}
                            handleDateChange={handleChangeDeliveryDate}
                            handleMonthChange={undefined}
                            onDayMouseUp={() => setShowCalendar(false)}
                        />
                    </Collapse>
                </Flex>
            </Collapse>
        </Flex>
    );
};

export default ScheduleSection;
