import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import Dropzone from 'react-dropzone';
import { BiCloudUpload, BiFile } from 'react-icons/bi';
import { FiAlertTriangle, FiCheck } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import { Flex, Text, PseudoBox, Button, useToast } from '@chakra-ui/core';

import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../../shared/utils/DateUtils';
import TextUtils from '../../../../shared/utils/TextUtils';

const OrderVoucherPage: React.FC = () => {
    const toast = useToast();
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const [voucher, setVoucher] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);

    const { changeTitle } = useLayout();

    const handleOnUploadFile = useCallback(async file => {
        setUploadedFile({
            file: file[0],
            name: file[0].name,
            preview: URL.createObjectURL(file[0]),
            uploaded: false,
            isLoading: false,
            progress: 0
        });
    }, []);

    const handleClickVoucher = useCallback(link => {
        if (link) {
            window.open(link, '_blank').focus();
        }
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            if (uploadedFile) {
                const datForm = new FormData();
                datForm.append('files', uploadedFile.file, uploadedFile.name);

                const response = await apiGateway.patch(
                    `/orders/${id}/voucher`,
                    datForm,
                    {
                        onUploadProgress: e => {
                            const progress = parseInt(
                                Math.round((e.loaded * 100) / e.total).toString(),
                                10
                            );

                            setUploadedFile(oldState => {
                                return {
                                    ...oldState,
                                    isLoading: progress !== 100,
                                    progress
                                };
                            });
                        }
                    }
                );

                if (response.status === HTTP.STATUS.SUCCESS) {
                    toast({
                        title: 'Comprovante Anexado',
                        description: 'O comprovante foi anexado com sucesso',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                } else {
                    toast({
                        title: 'Ocorreu um problema',
                        description: 'Não foi possível anexar o comprovante.',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    console.log('caiu aqui');
                }
            }
        } catch (error) {
            toast({
                title: 'Ocorreu um problema',
                description: 'Não foi possível anexar o comprovante.',
                status: 'success',
                duration: 4000,
                isClosable: true
            });

            console.log('log', error);
        }
    }, [id, toast, uploadedFile]);

    useEffect(() => {
        apiGateway.get(`/orders/${id}`).then(response => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                changeTitle(`Pedido - ${response.data.code} - Comprovante`);
                setVoucher(response.data.url_voucher);
                setOrder(response.data);
            }
        });
    }, [changeTitle, id]);

    return (
        <Flex width="100%" height="100%">
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column" overflow="auto">
                    <Flex
                        width="100%"
                        height="100%"
                        mt="8px"
                        mb="16px"
                        px="24px"
                        fontSize="14px"
                        justifyContent="space-between"
                    >
                        <Flex width="100%" height="100%">
                            {order && (
                                <Flex
                                    width="100%"
                                    height="100%"
                                    flexDirection="column"
                                    alignItems="center"
                                    p="16px"
                                >
                                    <Flex
                                        p="16px"
                                        width="100%"
                                        maxWidth="500px"
                                        backgroundColor="white"
                                    >
                                        <Flex
                                            width="100%"
                                            p="24px"
                                            flexDirection="column"
                                            alignItems="center"
                                            py="40px"
                                            border="2px dashed"
                                            borderColor="gray.700"
                                        >
                                            <Flex width="100%" justifyContent="space-between">
                                                <Flex flexDirection="column" color="purple.500">
                                                    <Text>Pedido:</Text>
                                                    <Text>Comprador:</Text>
                                                    <Text>CPF:</Text>
                                                    <Text fontSize="20px" mt="16px">
                            Valor a pagar:
                                                    </Text>
                                                </Flex>
                                                <Flex flexDirection="column" alignItems="flex-end">
                                                    <Text>{`${order.code}`}</Text>
                                                    <Text>{`${order.buyer.name}`}</Text>
                                                    <Text>
                                                        {order.buyer.document
                                                            ? TextUtils.mask(
                                                                order.buyer.document,
                                                                '###.###.###-##'
                                                            )
                                                            : 'Não Informado'}
                                                    </Text>

                                                    <Text fontSize="20px" mt="16px">
                                                        {`${CurrencyUtils.numberToCurrency(
                                                            order.total_value
                                                        )}`}
                                                    </Text>
                                                </Flex>
                                            </Flex>

                                            {!!voucher === false && (
                                                <>
                                                    {!!uploadedFile === false && (
                                                        <Flex width="100%" flexDirection="column" mt="48px">
                                                            <Text color="purple.500">ANEXAR COMPROVANTE</Text>

                                                            <Dropzone
                                                                accept={{
                                                                    'image/png': ['.jpeg', '.jpg', '.png'],
                                                                    'application/pdf': ['.pdf']
                                                                }}
                                                                onDropAccepted={handleOnUploadFile}
                                                            >
                                                                {({
                                                                    getRootProps,
                                                                    getInputProps,
                                                                    isDragActive,
                                                                    isDragReject
                                                                }) => (
                                                                    <Flex
                                                                        width="100%"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        mr="16px"
                                                                        {...getRootProps()}
                                                                        outline="none"
                                                                    >
                                                                        <input {...getInputProps()} />
                                                                        <PseudoBox
                                                                            mt="4px"
                                                                            display="flex"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                            flexDirection="column"
                                                                            border="2px dashed"
                                                                            borderColor="gray.600"
                                                                            width="100%"
                                                                            py="8px"
                                                                            _hover={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            {!isDragActive && !isDragReject && (
                                                                                <>
                                                                                    <BiCloudUpload size={40} />
                                                                                    <Text fontSize="14px" mt="4px">
                                            Arraste ou clique aqui
                                                                                    </Text>
                                                                                </>
                                                                            )}

                                                                            {isDragActive && !isDragReject && (
                                                                                <Flex
                                                                                    flexDirection="column"
                                                                                    color="green.500"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <FiCheck size={40} />
                                                                                    <Text fontSize="14px" mt="4px">
                                            Solte o arquivo aqui
                                                                                    </Text>
                                                                                </Flex>
                                                                            )}

                                                                            {isDragReject && (
                                                                                <Flex
                                                                                    flexDirection="column"
                                                                                    color="pink.500"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <FiAlertTriangle size={40} />
                                                                                    <Text fontSize="14px" mt="4px">
                                            Arquivo não suportado apenas PNG JPG
                                            ou PDF
                                                                                    </Text>
                                                                                </Flex>
                                                                            )}
                                                                        </PseudoBox>
                                                                    </Flex>
                                                                )}
                                                            </Dropzone>

                                                            <Text color="red.500" fontSize="12px">
                                Atenção apenas fotos ou arquivos em PDF
                                                            </Text>
                                                        </Flex>
                                                    )}

                                                    {uploadedFile && (
                                                        <Flex
                                                            width="100%"
                                                            maxWidth="400px"
                                                            flexDirection="column"
                                                            mt="48px"
                                                        >
                                                            <PseudoBox
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                            >
                                                                <Flex
                                                                    alignItems="center"
                                                                    onClick={() =>
                                                                        handleClickVoucher(uploadedFile.preview)
                                                                    }
                                                                    cursor="pointer"
                                                                >
                                                                    <PseudoBox
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        title="Ver Comprovante"
                                                                    >
                                                                        <BiFile size={40} />
                                                                    </PseudoBox>
                                                                    <Flex
                                                                        ml="8px"
                                                                        backgroundColor="purple.500"
                                                                        color="white"
                                                                        borderRadius="2px"
                                                                        px="8px"
                                                                        py="4px"
                                                                        cursor="pointer"
                                                                    >
                                    PRÉ-VISUALIZAR
                                                                    </Flex>
                                                                </Flex>
                                                            </PseudoBox>
                                                        </Flex>
                                                    )}

                                                    {uploadedFile && (
                                                        <Flex width="100%" mt="40px">
                                                            <Button
                                                                width="100%"
                                                                type="submit"
                                                                display="flex"
                                                                backgroundColor="purple.500"
                                                                color="white"
                                                                borderRadius="2px"
                                                                justifyContent="center"
                                                                py="8px"
                                                                cursor="pointer"
                                                                _hover={{
                                                                    backgroundColor: 'green.500'
                                                                }}
                                                                onClick={() => handleSubmit()}
                                                                fontWeight="500"
                                                            >
                                                                {uploadedFile.isLoading === false && (
                                                                    <Text fontSize="24px">Confirmar Anexo</Text>
                                                                )}

                                                                {uploadedFile.isLoading && (
                                                                    <CircularProgressbar
                                                                        styles={{
                                                                            root: { width: 32 },
                                                                            path: { stroke: '#f1f1f1' }
                                                                        }}
                                                                        strokeWidth={10}
                                                                        value={uploadedFile.progress}
                                                                    />
                                                                )}
                                                            </Button>
                                                        </Flex>
                                                    )}
                                                </>
                                            )}

                                            {!!voucher === true && (
                                                <Flex
                                                    width="100%"
                                                    maxWidth="400px"
                                                    flexDirection="column"
                                                    mt="48px"
                                                >
                                                    <PseudoBox
                                                        display="flex"
                                                        flexDirection="column"
                                                        alignItems="center"
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            onClick={() => handleClickVoucher(voucher)}
                                                            cursor="pointer"
                                                        >
                                                            <PseudoBox
                                                                display="flex"
                                                                flexDirection="column"
                                                                title="Ver Comprovante"
                                                            >
                                                                <BiFile size={40} />
                                                            </PseudoBox>
                                                            <Flex
                                                                ml="8px"
                                                                backgroundColor="purple.500"
                                                                color="white"
                                                                borderRadius="2px"
                                                                px="8px"
                                                                py="4px"
                                                                cursor="pointer"
                                                            >
                                VER COMPROVANTE
                                                            </Flex>
                                                        </Flex>

                                                        <Text mt="8px">
                                                            {`Anexado dia ${DateUtils.format(
                                                                order.updated_at,
                                                                'dd/MM/yyyy \'ás\' hh:mm'
                                                            )}`}
                                                        </Text>
                                                    </PseudoBox>

                                                    <Flex flexDirection="column">
                                                        <Button
                                                            mt="40px"
                                                            type="submit"
                                                            display="flex"
                                                            backgroundColor="purple.500"
                                                            color="white"
                                                            borderRadius="2px"
                                                            justifyContent="center"
                                                            py="8px"
                                                            cursor="pointer"
                                                            _hover={{
                                                                backgroundColor: 'green.500'
                                                            }}
                                                            height="48px"
                                                            onClick={() => setVoucher(null)}
                                                            fontWeight="500"
                                                        >
                                                            <Text fontSize="24px">Editar Comprovante</Text>
                                                        </Button>
                                                        <Text
                                                            color="red.500"
                                                            fontSize="12px"
                                                            mt="4px"
                                                            lineHeight="16px"
                                                        >
                              Atenção: está ação substituirá o comprovante
                              anexado após a confirmação.
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default OrderVoucherPage;
