import React, { useEffect, useState } from 'react';

import {
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Flex,
    Image,
    Box
} from '@chakra-ui/core';

import { HTTP } from '../../../../../shared/constants';
import apiGateway from '../../../../../shared/services/apiGateway';

interface IProps {
  productId: any;
  isOpen: any;
  onClose: any;
}

const ModalViewProduct: React.FC<IProps> = ({ productId, isOpen, onClose }) => {
    const [product, setProduct] = useState(null);

    useEffect(() => {
        if (productId) {
            apiGateway.get(`/products/${productId}`).then(response => {
                const productResponse = response.data;

                if (response.status === HTTP.STATUS.SUCCESS) {
                    setProduct(productResponse);
                }
            });
        }
    }, [productId]);

    return (
        product && (
            <>
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    isCentered
                    initialFocusRef={null}
                    size="xl"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader fontWeight="500">{product.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody height="400px">
                            <Flex alignItems="center" justifyContent="space-between">
                                <Flex width="40%" mr="24px">
                                    <Box width="100%" height="100%">
                                        <Image src={product.cover.thumb_url} width="100%" />
                                    </Box>
                                </Flex>
                                <Flex
                                    width="60%"
                                    overflow="auto"
                                    p="8px"
                                    flexDirection="column"
                                >
                                    <Text mb="16px" fontWeight={500}>
                    Descrição
                                    </Text>
                                    <Text whiteSpace="pre-wrap">{product.description}</Text>
                                </Flex>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        )
    );
};

export default ModalViewProduct;
