import React, { useCallback, useEffect, useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Badge,
    Divider,
    Flex,
    PseudoBox,
    Text,
    Checkbox
} from '@chakra-ui/core';

import List from '../../../components/List';
import ListEmpty from '../../../components/List/ListEmpty';
import ListRow from '../../../components/List/ListRow';
import { useLayout } from '../../../layouts/default';
import apiGateway from '../../../shared/services/apiGateway';

const ChannelPage: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();
    const [channels, setChannels] = useState([]);
    const handleEdit = useCallback(
        (id: string) => {
            navigate(`/channels/edit/${id}`);
        },
        [navigate]
    );

    const ChannelRow = ({ style, index, data }: any) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>
                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].name}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Badge
                        width="100%"
                        maxWidth="80px"
                        textAlign="center"
                        backgroundColor={data[index].is_working ? 'green.500' : 'pink.500'}
                        color="white"
                        py="4px"
                    >
                        {data[index].is_working ? 'Ativo' : 'Inativo'}
                    </Badge>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <PseudoBox
                        border="2px solid"
                        borderColor="purple.500"
                        title="Configurações"
                        p="8px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => handleEdit(data[index].id)}
                    >
                        <FiSettings size={14} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Canais de Venda');
        apiGateway.get('/channels').then(response => {
            setChannels(response.data);
        });
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center" />

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="24px" color="purple.500">
                                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="40px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Nome</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Situação</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                            <Text>Ação</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {channels.length > 0 && (
                        <List item={channels} row={ChannelRow} itemHeight={96} />
                    )}

                    {channels.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ChannelPage;
