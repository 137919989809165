import React, { useCallback, useEffect, useState } from 'react';
import { FiTrash2, FiEdit2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Badge,
    Divider,
    Flex,
    PseudoBox,
    Text,
    Checkbox
} from '@chakra-ui/core';

import Button from '../../../components/Button';
import List from '../../../components/List';
import ListEmpty from '../../../components/List/ListEmpty';
import ListRow from '../../../components/List/ListRow';
import { useLayout } from '../../../layouts/default';
import apiGateway from '../../../shared/services/apiGateway';

const PagePage: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();
    const [selectedPagesIds, setSelectedPagesIds] = useState([]);
    const [pages, setPages] = useState([]);
    const handleDeletePages = useCallback(
        (pagesIds: string[]) => {
            apiGateway
                .delete('/pages', {
                    params: { 
                        ids: pagesIds
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        const updatedListProduct = pages.filter(
                            product => !pagesIds.includes(product.id)
                        );
                        setPages(updatedListProduct);
                    }
                });
        },
        [pages]
    );

    const handleChangeSelectedPagesIds = useCallback(
        (input: EventTarget & HTMLInputElement) => {
            let list = selectedPagesIds;

            if (input.checked) {
                if (!selectedPagesIds.includes(input.value)) {
                    list.push(input.value);
                }
            } else {
                list = list.filter(item => item !== input.value);
            }

            setSelectedPagesIds(list);
        },
        [selectedPagesIds]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate(`/settings/pages/edit/${id}`);
        },
        [navigate]
    );

    const PageRow = ({ style, index, data }: any) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onChange={e => handleChangeSelectedPagesIds(e.currentTarget)}
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>
                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].title}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].slug}</Text>
                </Flex>
                <Flex width="100%" justifyContent="center">
                    <Badge
                        width="100%"
                        maxWidth="80px"
                        textAlign="center"
                        backgroundColor={data[index].is_enable ? 'green.500' : 'pink.500'}
                        color="white"
                        py="4px"
                    >
                        {data[index].is_enable ? 'Ativo' : 'Inativo'}
                    </Badge>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <PseudoBox
                        title="Visualizar"
                        p="8px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => handleEdit(data[index].id)}
                    >
                        <FiEdit2 size={14} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Pages');
        apiGateway.get('/pages').then(response => {
            setPages(response.data);
        });
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="200px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    onClick={() => navigate('/settings/pages/register')}
                >
                    Novo Página
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Excluir Categorias"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeletePages(selectedPagesIds)}
                >
                    <FiTrash2 size={20} />
                </Button>
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="24px" color="purple.500">
                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="40px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Título</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Slug</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Situação</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                            <Text>Ação</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {pages.length > 0 && (
                        <List item={pages} row={PageRow} itemHeight={96} />
                    )}

                    {pages.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default PagePage;
