export default class CurrencyUtils {
    static numberToCurrency(value: any, currency = 'BRL'): string {
        return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency
        }).format(value);
    }

    static currencyToNumber(value: string): number {
        const number = value.replace(/./g, '').replace(/,/g, '.');

        return parseFloat(number);
    }
}
