import React from 'react';

import { Flex } from '@chakra-ui/core';

interface IProps {
  style: any;
  index: number;
  children: any;
}
const ListRow: React.FC<IProps> = ({ style, index, children }) => {
    return (
        <Flex
            justifyContent="column"
            width="100%"
            style={style}
            backgroundColor={index % 2 === 0 ? 'gray.100' : 'white'}
        >
            <Flex
                key={index}
                width="100%"
                py="8px"
                justifyContent="space-between"
                alignItems="center"
                maxWidth="100%"
                fontSize="12px"
            >
                {children}
            </Flex>
        </Flex>
    );
};

export default ListRow;
