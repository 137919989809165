import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Box, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import TextArea from '../../../components/Form/TextArea';
import TextEditor from '../../../components/Form/TextEditor';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import apiGateway from '../../../shared/services/apiGateway';

const PagesEdit: React.FC = () => {
    const { id } = useParams() as any;

    const formRef = useRef<FormHandles>();

    const [page, setPage] = useState(null);

    const [isEnable, setEnable] = useState(false);
    const [showInHeader, setShowInHeader] = useState('false');

    const toast = useToast();
    const navigate = useNavigate();

    const handleChangeTitle = useCallback(() => {
        const title = formRef.current.getFieldValue('title');

        formRef.current.getFieldRef('slug').value = title;
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.put(`/pages/${id}`, {
                    title: formData.title,
                    slug: formData.slug,
                    content: formData.content,
                    meta_description: formData.meta_description,
                    is_enable: isEnable,
                    show_in_header: showInHeader
                });

                if (response.status === HTTP.STATUS.SUCCESS) {
                    FormHelper.onSuccess(
                        'Edição Realizada',
                        'Página atualizada com sucesso',
                        toast
                    );

                    navigate('/settings/pages');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [navigate, id, isEnable, showInHeader, toast]
    );

    useEffect(() => {
        apiGateway.get(`/pages/${id}`).then(response => {
            const pageData = response.data;

            setPage(pageData);
            setEnable(pageData.is_enable);
            setShowInHeader(pageData.show_in_header ? 'true' : 'false');
        });
    }, [id]);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
        >
            {page && (
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontSize="14px"
                >
                    <Text color="purple.500" fontSize="20px" mt="16px">
            Informações Básicas
                    </Text>
                    <Flex
                        minWidth="100%"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        mt="8px"
                    >
                        <Box width="32%">
                            <Input
                                name="title"
                                label="Título"
                                isRequired
                                size="sm"
                                defaultValue={page.title}
                                onChange={() => handleChangeTitle()}
                            />
                        </Box>
                        <Box width="32%">
                            <Input
                                name="slug"
                                label="Slug"
                                isRequired
                                size="sm"
                                defaultValue={page.slug}
                            />
                        </Box>

                        <Flex width="32%" mb="16px">
                            <Box fontWeight="500" mr="48px">
                                <Text>Ativar page?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={isEnable === false}
                                        onChange={() => setEnable(!isEnable)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>

                                    <Checkbox
                                        name="is_enable"
                                        isChecked={isEnable}
                                        onChange={() => setEnable(!isEnable)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>

                        <Flex width="32%" mb="16px">
                            <Box fontWeight="500" mr="48px">
                                <Text>Mostrar no cabeçalho?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={showInHeader !== 'true'}
                                        onChange={() =>
                                            setShowInHeader(
                                                showInHeader === 'true' ? 'false' : 'true'
                                            )
                                        }
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>

                                    <Checkbox
                                        name="is_enable"
                                        isChecked={showInHeader === 'true'}
                                        onChange={() =>
                                            setShowInHeader(
                                                showInHeader === 'true' ? 'false' : 'true'
                                            )
                                        }
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>

                    <Box width="100%" mb="16px">
                        <TextArea
                            name="meta_description"
                            label="Meta Description"
                            isRequired
                            size="sm"
                            mb="4px"
                            defaultValue={page.meta_description}
                        />
                    </Box>

                    <Box width="100%">
                        <TextEditor
                            name="content"
                            label="Conteúdo"
                            isRequired
                            size="sm"
                            mb="4px"
                            height={800}
                            defaultValue={page.content}
                        />
                    </Box>

                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
            Cadastrar
                    </Button>
                </Flex>
            )}
        </Form>
    );
};

export default PagesEdit;
