import React, { useCallback, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Divider, Flex, PseudoBox, Text, Checkbox } from '@chakra-ui/core';

import Button from '../../../components/Button';
import List from '../../../components/List';
import ListEmpty from '../../../components/List/ListEmpty';
import ListRow from '../../../components/List/ListRow';
import { useLayout } from '../../../layouts/default';
import apiGateway from '../../../shared/services/apiGateway';
import DateUtils from '../../../shared/utils/DateUtils';

const CitiesPage: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();

    const [selectedCitiesIds, setSelectedCitiesIds] = useState([]);

    const [cities, setCities] = useState([]);

    const handleDeleteCities = useCallback(
        () => {
            apiGateway
                .delete('/cities', {
                    params: {
                        ids: selectedCitiesIds
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        navigate(0);
                    }
                });
        },
        [cities]
    );

    const handleChangeSelectedCitiesIds = useCallback(
        (input: any) => {
            let list = selectedCitiesIds;

            if (input.checked) {
                if (!selectedCitiesIds.includes(input.value as never)) {
                    list.push(input.value as never);
                }
            } else {
                list = list.filter(item => item !== input.value);
            }

            setSelectedCitiesIds(list);
        },
        [selectedCitiesIds]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate(`/settings/cities/edit/${id}`);
        },
        [navigate]
    );

    const CityRow: React.FC<any> = ({ style, index, data }) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onChange={e => handleChangeSelectedCitiesIds(e.currentTarget)}
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].name}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{DateUtils.format(data[index].created_at, 'dd/MM/yy')}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <PseudoBox
                        border="2px solid"
                        borderColor="purple.500"
                        title="Configurações"
                        p="8px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => handleEdit(data[index].id)}
                    >
                        <FiEdit size={14} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Cidades');
        apiGateway.get('/cities').then(response => {
            setCities(response.data);
        });
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="200px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    onClick={() => navigate('/settings/cities/register')}
                >
          Nova Cidade
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Excluir Cidades"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeleteCities()}
                >
                    <FiTrash2 size={20} />
                </Button>
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="24px" color="purple.500">
                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="40px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Nome</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Criado em</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                            <Text>Ação</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {cities.length > 0 && (
                        <List item={cities} row={CityRow} itemHeight={48} />
                    )}

                    {cities.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default CitiesPage;
