import React from 'react';

import { Form as FormUnform } from '@unform/web';

const Form: React.FC<any> = React.forwardRef(({ children, ...rest }, ref) => {
    return (
        <FormUnform
            ref={ref as any}
            style={{
                width: '100%'
            }}
            autoComplete="off"
            onKeyPress={e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                }
            }}
            {...rest}
        >
            {children}
        </FormUnform>
    );
});

export default Form;
