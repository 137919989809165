import React, { useCallback, useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Divider, Flex, Text, Checkbox } from '@chakra-ui/core';

import Button from '../../../components/Button';
import List from '../../../components/List';
import ListEmpty from '../../../components/List/ListEmpty';
import ListRow from '../../../components/List/ListRow';
import { useLayout } from '../../../layouts/default';
import apiGateway from '../../../shared/services/apiGateway';
import CurrencyUtils from '../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../shared/utils/DateUtils';
import { HTTP } from '../../../shared/constants';

const CuponsPage: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();
    const [selectedCuponsIds, setSelectedCuponsIds] = useState([]);
    const [cupons, setCupons] = useState([]);

    const handleDeleteCupons = useCallback(() => {
        apiGateway
            .delete('/cupons', {
                params: {
                    ids: selectedCuponsIds
                }
            })
            .then(response => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    navigate(0);
                }
            });
    }, [selectedCuponsIds]);

    const handleChangeSelectedCuponsIds = useCallback(
        (input: any) => {
            let list = selectedCuponsIds;

            if (input.checked) {
                if (!selectedCuponsIds.includes(input.value as never)) {
                    list.push(input.value as never);
                }
            } else {
                list = list.filter(item => item !== input.value);
            }

            setSelectedCuponsIds(list);
        },
        [selectedCuponsIds]
    );

    const CuponRow: React.FC<any> = ({ style, index, data }) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onChange={e => handleChangeSelectedCuponsIds(e.currentTarget)}
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].name}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].type}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{CurrencyUtils.numberToCurrency(data[index].value)}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{DateUtils.format(data[index].created_at, 'dd/MM/yy')}</Text>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Cupons');
        apiGateway.get('/cupons').then(response => {
            setCupons(response.data);
        });
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="200px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    onClick={() => navigate('/settings/cupons/register')}
                >
          Novo Cupon
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Excluir Cupons"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeleteCupons()}
                >
                    <FiTrash2 size={20} />
                </Button>
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="24px" color="purple.500">
                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="40px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Cupon</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Tipo</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Valor</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Criado em</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {cupons.length > 0 && (
                        <List item={cupons} row={CuponRow} itemHeight={48} />
                    )}

                    {cupons.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default CuponsPage;
