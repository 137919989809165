import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FiAward, FiTrash2 } from 'react-icons/fi';

import { Flex, PseudoBox, Box, Text, Image } from '@chakra-ui/core';

import FileUpload from '../../../../../components/Form/FileUpload';
import { useProductForm } from '../../../contexts/ProductFormProvider';

interface IProps {
  defaultImage?: any;
}

const UploadProductImage: React.FC<IProps> = ({ defaultImage = undefined }) => {
    const [uploadedImage, setUploadedImage] = useState(defaultImage);
    const { addImages, removeImage, setCover, getAttributes } = useProductForm();

    const onUploadImage = useCallback(
        imageUploaded => {
            setUploadedImage(imageUploaded);

            if (imageUploaded.uploaded === true) {
                addImages(imageUploaded);
            }
        },
        [addImages]
    );

    const onChangeCover = useCallback(() => {
        setCover(uploadedImage);
    }, [setCover, uploadedImage]);

    const onRemoveImage = useCallback(() => {
        removeImage(uploadedImage);
    }, [removeImage, uploadedImage]);

    useEffect(() => {
        const { images } = getAttributes();

        setUploadedImage(oldState => {
            const image = images.find(item => item.id === oldState?.id);

            if (image && !defaultImage) {
                return {
                    ...oldState,
                    is_cover: image.is_cover
                };
            }

            return defaultImage;
        });
    }, [defaultImage, getAttributes]);

    return !uploadedImage ? (
        <Flex width="244px" height="160px">
            <FileUpload setUploadedImage={onUploadImage} />
        </Flex>
    ) : (
        <Flex
            key={uploadedImage.id}
            flexDirection="column"
            alignItems="center"
            mr="16px"
            position="relative"
        >
            <PseudoBox
                mt="8px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="200px"
                height="160px"
                _hover={{
                    cursor: 'pointer',
                    color: 'green.500'
                }}
            >
                <Flex
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    {!uploadedImage.uploaded && !uploadedImage.error && (
                        <CircularProgressbar
                            styles={{
                                root: { width: 48 },
                                path: { stroke: '#1EBD94' }
                            }}
                            strokeWidth={10}
                            value={uploadedImage.progress}
                        />
                    )}

                    {uploadedImage.uploaded && <Image src={uploadedImage.preview} />}
                </Flex>
            </PseudoBox>

            {uploadedImage.is_cover && (
                <Text fontSize="12px" color="green.500" mt="24px">
          FOTO CAPA
                </Text>
            )}

            {!uploadedImage.is_cover && (
                <Box
                    p="4px"
                    backgroundColor="green.500"
                    color="white"
                    borderRadius="2px"
                    position="absolute"
                    top="0px"
                    right="20px"
                    title="Definar como capa"
                    cursor="pointer"
                    onClick={() => {
                        onChangeCover();
                    }}
                >
                    <FiAward size={16} />
                </Box>
            )}

            <Box
                p="4px"
                backgroundColor="pink.500"
                color="white"
                borderRadius="2px"
                position="absolute"
                top="0px"
                right="-10px"
                title="Excluir imagem"
                cursor="pointer"
                onClick={() => onRemoveImage()}
            >
                <FiTrash2 size={16} />
            </Box>
        </Flex>
    );
};

export default UploadProductImage;
