import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Divider, Flex, Link, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Form from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';
import Select from '../../../../../components/Form/Select';
import { useLayout } from '../../../../../layouts/default';
import { HTTP } from '../../../../../shared/constants';
import apiGateway from '../../../../../shared/services/apiGateway';

const IuguSettings: React.FC = () => {
    const { changeTitle } = useLayout();

    const { id } = useParams<{ id: string }>();
    const [platform, setPlatform] = useState(null);

    const formRef = useRef<FormHandles>(null);
    const toast = useToast();

    const handleSubmit = useCallback(
        async data => {
            const response = await apiGateway.put(
                `/settings/payment_platforms/${platform.id}`,
                {
                    ...platform,
                    config: data
                }
            );

            if (response.status === HTTP.STATUS.SUCCESS) {
                toast({
                    title: 'Configuração Atualizada',
                    description: 'Sua configuração foi atualizada com sucesso',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });
            }
        },

        [platform, toast]
    );

    const getDefaultValue = useCallback(
        (item, defaultValue = '0') => {
            return platform?.config[item] || defaultValue;
        },
        [platform]
    );

    useEffect(() => {
        changeTitle('Configurarações Iugu');

        apiGateway.get(`/settings/payment_platforms/${id}`).then(response => {
            setPlatform(response.data);
        });
    }, [changeTitle, id]);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="white"
                        px="24px"
                    >
                        <Flex
                            width="100%"
                            py="16px"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex
                                    width="100%"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Text mr="16px" fontSize="24px" color="purple.500">
                    Configurarações do Iugu
                                    </Text>
                                </Flex>

                                {platform && (
                                    <Flex width="100%">
                                        <Flex width="100%" mt="24px">
                                            <Flex width="48%" flexDirection="column">
                                                <Text width="100%" fontSize="20px">
                          Parcelamento
                                                </Text>

                                                <Select
                                                    width="200px"
                                                    name="max_installments"
                                                    label="Número máximo de parcelas"
                                                    size="sm"
                                                    defaultValue={getDefaultValue('max_installments', '1')}
                                                >
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                    <option>8</option>
                                                    <option>9</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                </Select>

                                                <Flex width="100%" flexWrap="wrap">
                                                    <Text width="100%">Taxas de Parcelamento</Text>

                                                    <Flex width="50%" flexDirection="column">
                                                        <Flex width="160px" mt="16px">
                                                            <Input
                                                                name="installment_1"
                                                                label="Parcela 1"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_1')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_3"
                                                                label="Parcela 3"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_3')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_5"
                                                                label="Parcela 5"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_5')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_7"
                                                                label="Parcela 7"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_7')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_9"
                                                                label="Parcela 9"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_9')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_11"
                                                                label="Parcela 11"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_11')}
                                                            />
                                                        </Flex>
                                                    </Flex>

                                                    <Flex width="50%" flexDirection="column">
                                                        <Flex width="160px" mt="16px">
                                                            <Input
                                                                name="installment_2"
                                                                label="Parcela 2"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_2')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_4"
                                                                label="Parcela 4"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_4')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_6"
                                                                label="Parcela 6"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_6')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_8"
                                                                label="Parcela 8"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_8')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_10"
                                                                label="Parcela 10"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_10')}
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="installment_12"
                                                                label="Parcela 12"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                                defaultValue={getDefaultValue('installment_12')}
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            <Flex width="48%" flexDirection="column">
                                                <Flex width="100%">
                                                    <Input
                                                        name="key"
                                                        label="Token"
                                                        defaultValue={getDefaultValue('key', '')}
                                                    />
                                                </Flex>

                                                <Flex width="208px" flexDirection="column" mb="16px">
                                                    <Input
                                                        name="comission"
                                                        label="Comissão da Plataforma"
                                                        defaultValue={getDefaultValue('comission', '0')}
                                                        isReadOnly
                                                        badge="porcentage"
                                                        mb="0px"
                                                    />
                                                    <Link href="/" fontSize="12px" fontWeight="400">
                            Clique aqui para saber mais.
                                                    </Link>
                                                </Flex>

                                                <Flex width="100%">
                                                    <Button
                                                        type="submit"
                                                        width="100%"
                                                        backgroundColor="purple.500"
                                                        height="48px"
                                                        mt="24px"
                                                        color="white"
                                                        _hover={{
                                                            backgroundColor: 'green.500'
                                                        }}
                                                        fontSize="24px"
                                                        fontWeight="400"
                                                        borderRadius="2px"
                                                    >
                            Confirmar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                            <Divider my="0px" />
                        </Flex>
                        <Divider my="0px" />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default IuguSettings;
