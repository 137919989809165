import * as Yup from 'yup';

export default class CategoryValidator {
    static async check(productData) {
        const schemaValidation = Yup.object().shape({
            name: Yup.string().required('O nome da categoria não foi informado.'),
            show_in_header: Yup.boolean().required(),
            show_in_footer: Yup.boolean().required(),
            title: Yup.string().required(
                'O titulo da pagina da categoria não foi informado'
            ),
            slug: Yup.string().required('O slug da categoria não foi informado'),
            meta_description: Yup.string().required(
                'O meta description da categoria não foi informado'
            )
        });

        await schemaValidation.validate(productData, {
            abortEarly: false
        });
    }
}
