import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FiTrash2 } from 'react-icons/fi';

import { Flex, PseudoBox, Box, Image } from '@chakra-ui/core';

import FileUploadBanner from '../FileUploadBanner';

interface IProps {
  defaultImage?: any;
  onUploadFunction: (url: string) => void;
}

const UploadBannerImage: React.FC<IProps> = ({
    defaultImage,
    onUploadFunction
}) => {
    const [uploadedImage, setUploadedImage] = useState(defaultImage);

    const onUploadImage = useCallback(
        imageUploaded => {
            setUploadedImage(imageUploaded);

            if (imageUploaded.uploaded === true) {
                onUploadFunction(imageUploaded.url);
            }
        },
        [onUploadFunction]
    );

    const onRemoveImage = useCallback(() => {
        setUploadedImage(undefined);
    }, []);

    useEffect(() => {
        if (defaultImage) {
            setUploadedImage({
                url: defaultImage,
                preview: defaultImage,
                uploaded: true
            });
        }
    }, [defaultImage]);

    return !uploadedImage ? (
        <FileUploadBanner setUploadedImage={onUploadImage} />
    ) : (
        <Flex
            key={uploadedImage.id}
            flexDirection="column"
            alignItems="center"
            mr="16px"
            position="relative"
        >
            <PseudoBox
                mt="8px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="200px"
                height="160px"
                _hover={{
                    cursor: 'pointer',
                    color: 'green.500'
                }}
            >
                <Flex
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    {!uploadedImage.uploaded && !uploadedImage.error && (
                        <CircularProgressbar
                            styles={{
                                root: { width: 48 },
                                path: { stroke: '#1EBD94' }
                            }}
                            strokeWidth={10}
                            value={uploadedImage.progress}
                        />
                    )}

                    {uploadedImage.uploaded && <Image src={uploadedImage.preview} />}
                </Flex>
            </PseudoBox>

            <Box
                p="4px"
                backgroundColor="pink.500"
                color="white"
                borderRadius="2px"
                position="absolute"
                top="0px"
                right="-10px"
                title="Excluir imagem"
                cursor="pointer"
                onClick={() => onRemoveImage()}
            >
                <FiTrash2 size={16} />
            </Box>
        </Flex>
    );
};

export default UploadBannerImage;
