import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Box, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import TextArea from '../../../components/Form/TextArea';
import { useLayout } from '../../../layouts/default';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import apiGateway from '../../../shared/services/apiGateway';
import Turns from '../components/Turns';

interface ITurn {
  startHour: string;
  endHour: string;
  isActive: boolean;
}

interface ITimeSettings {
  [day: string]: {
    MORNING: ITurn;
    EVENING: ITurn;
    NIGHT: ITurn;
    UNIQUE: ITurn;
    isActive: boolean;
  };
}

const ChannelEditPage: React.FC = () => {
    const { id } = useParams() as any;

    const formRef = useRef<FormHandles>();

    const [DAYS_OF_WEEK] = useState([
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
        'SUNDAY'
    ]);

    const [channel, setChannel] = useState(null);
    const [timeSettings, setTimeSettings] = useState<ITimeSettings>(null);

    const [isEnable, setEnable] = useState(false);

    const toast = useToast();
    const navigate = useNavigate();
    const { changeTitle } = useLayout();

    const handleChangeDayIsActive = useCallback(day => {
        setTimeSettings(oldState => {
            const updateState = oldState;

            return {
                ...updateState,
                [day]: {
                    ...updateState[day],
                    isActive: !updateState[day].isActive
                }
            };
        });
    }, []);

    const handleChangeTurnIsActive = useCallback((day, turn) => {
        setTimeSettings(oldState => {
            const updateState = oldState;

            return {
                ...updateState,
                [day]: {
                    ...updateState[day],
                    [turn]: {
                        ...updateState[day][turn],
                        isActive: !updateState[day][turn].isActive
                    }
                }
            };
        });
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const updateTimeSetting = timeSettings;

                DAYS_OF_WEEK.forEach(day => {
                    updateTimeSetting[day].MORNING.startHour =
            formData[`${day}_MORNING_START_HOUR`];

                    updateTimeSetting[day].MORNING.endHour =
            formData[`${day}_MORNING_END_HOUR`];

                    updateTimeSetting[day].EVENING.startHour =
            formData[`${day}_EVENING_START_HOUR`];

                    updateTimeSetting[day].EVENING.endHour =
            formData[`${day}_EVENING_END_HOUR`];

                    updateTimeSetting[day].NIGHT.startHour =
            formData[`${day}_NIGHT_START_HOUR`];

                    updateTimeSetting[day].NIGHT.endHour =
            formData[`${day}_NIGHT_END_HOUR`];

                    updateTimeSetting[day].UNIQUE.startHour =
            formData[`${day}_UNIQUE_START_HOUR`];

                    updateTimeSetting[day].UNIQUE.endHour =
            formData[`${day}_UNIQUE_END_HOUR`];
                });

                const response = await apiGateway.put(`/channels/${id}`, {
                    name: formData.name,
                    description: formData.description,
                    store_name_marketplace: formData.store_name_marketplace,
                    time_settings: updateTimeSetting,
                    opening_hours: formData.opening_hours,
                    delivery_observations: formData.delivery_observations
                });

                if (response.status === HTTP.STATUS.SUCCESS) {
                    FormHelper.onSuccess('Sucesso', 'Configuração salva', toast);

                    navigate('/channels');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [DAYS_OF_WEEK, navigate, id, timeSettings, toast]
    );

    useEffect(() => {
        apiGateway.get(`/channels/${id}`).then(response => {
            const { data, status } = response;

            if (status === HTTP.STATUS.SUCCESS) {
                setChannel(data);
                setTimeSettings(data.time_settings);
                changeTitle(`Canal ${data.name}`);
            }
        });
    }, [changeTitle, id]);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
        >
            {channel && (
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontSize="14px"
                >
                    <Text color="purple.500" fontSize="20px" mt="16px">
            Configurações
                    </Text>
                    <Flex
                        minWidth="100%"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        mt="8px"
                    >
                        <Box width="32%">
                            <Input
                                name="name"
                                label="Nome do Canal"
                                isRequired
                                size="sm"
                                defaultValue={channel.name}
                                isReadOnly
                            />
                        </Box>
                        <Box width="32%">
                            <Input
                                name="store_name_marketplace"
                                label="Nome da Loja"
                                isRequired
                                size="sm"
                                defaultValue={channel.store_name_marketplace}
                            />
                        </Box>

                        <Flex width="32%" flexDirection="column">
                            <Box fontWeight="500">
                                <Text>Utilizar canal?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                >
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={isEnable === false}
                                        onChange={() => setEnable(!isEnable)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>

                                    <Checkbox
                                        name="is_enable"
                                        isChecked={isEnable}
                                        onChange={() => setEnable(!isEnable)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>

                    <Box width="100%" mb="16px">
                        <TextArea
                            name="description"
                            label="Descrição"
                            isRequired
                            size="sm"
                            mb="4px"
                            defaultValue={channel.description}
                        />
                    </Box>

                    <Flex width="100%" flexDirection="column">
                        <Text color="purple.500" fontSize="20px" mt="16px">
              Dias e Horários de Entregas
                        </Text>

                        <Flex flexDirection="column">
                            <Flex justifyContent="space-between">
                                <Text>Horários para aceitar pedidos</Text>
                            </Flex>

                            {timeSettings && (
                                <Turns
                                    timeSettings={timeSettings}
                                    handleChangeDayIsActive={handleChangeDayIsActive}
                                    handleChangeTurnIsActive={handleChangeTurnIsActive}
                                />
                            )}
                        </Flex>

                        <Box width="100%" my="16px">
                            <TextArea
                                name="delivery_observations"
                                label="Observações Sobre as Entregas"
                                size="sm"
                                mb="4px"
                                defaultValue={channel.delivery_observations}
                            />
                        </Box>

                        <Box width="100%">
                            <TextArea
                                name="opening_hours"
                                label="Horário de Atendimento (descritivo)"
                                isRequired
                                size="sm"
                                mb="4px"
                                defaultValue={channel.opening_hours}
                            />
                        </Box>
                    </Flex>

                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
            Salvar
                    </Button>
                </Flex>
            )}
        </Form>
    );
};

export default ChannelEditPage;
