import React, { useCallback, useState } from 'react';

import { Collapse, Flex } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Input from '../../../../../../components/Form/Input';
import TextArea from '../../../../../../components/Form/TextArea';
import CollapseCheckoutBox from '../../../../components/CollapseCheckoutBox';
import { useCashier } from '../../../../hooks/useCashier';

interface IProps {
  formRef: React.MutableRefObject<FormHandles>;
}

const DetailsSection: React.FC<IProps> = ({ formRef }) => {
    const { getStateBox, collapseBox } = useCashier();

    const [sectionStatus, setSectionStatus] = useState({
        isSuccess: false,
        isError: false
    });

    const handleRequiredFields = useCallback(() => {
        const value1 = formRef.current.getFieldValue('destinatary_name');
        const value2 = formRef.current.getFieldValue('destinatary_telephone');

        if (value1 && value2) {
            formRef.current.setFieldError('destinatary_name', '');
            formRef.current.setFieldError('destinatary_telephone', '');

            setSectionStatus({
                isSuccess: true,
                isError: false
            });
        } else {
            setSectionStatus({
                isSuccess: false,
                isError: false
            });
        }
    }, []);

    return (
        <Flex width={'100%'} flexDirection="column">
            <CollapseCheckoutBox
                title="Detalhes Destinatário"
                isCollapsed={getStateBox('DETAILS')}
                onCollapse={() => collapseBox('DETAILS')}
                isComplete={sectionStatus.isSuccess}
            />

            <Collapse isOpen={getStateBox('DETAILS')} pt="8px">
                <Input
                    name="destinatary_name"
                    backgroundColor="gray.500"
                    placeholder="Nome destinatário"
                    borderRadius="2px"
                    size="sm"
                    mb="8px"
                    onBlur={() => handleRequiredFields()}
                />

                <Input
                    name="destinatary_telephone"
                    backgroundColor="gray.500"
                    placeholder="Telefone Destinatário"
                    borderRadius="2px"
                    size="sm"
                    mb="8px"
                    onBlur={() => handleRequiredFields()}
                />

                <TextArea
                    name="card_message"
                    placeholder="Mensagem do destinatário"
                    backgroundColor="gray.500"
                    color="gray.800"
                    size="sm"
                />
            </Collapse>
        </Flex>
    );
};

export default DetailsSection;
