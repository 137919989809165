import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';

interface IProduct {
  id: string;
  name: string;
  price: number;
  quantity: number;
  url_thumb?: string;
}

type BoxName =
  | 'CUSTOMER'
  | 'ADDRESS'
  | 'DETAILS'
  | 'SCHEDULE'
  | 'PRODUCTS'
  | 'DESCOUNT'
  | 'PAYMENT_METHOD'
  | 'OBSERVATIONS';

interface ICashierContextData {
  collapseBox(boxName: BoxName): void;
  getStateBox(boxName: BoxName): boolean;
  addProduct(product: any, quantity?: number): void;
  removeProduct(product_id: string): void;
  getProducts(): any[];
  getTotalProducts(): number;
  addDeliveryValue(value: number): void;
  getTotalDelivery(): number;
  addTaxValue(value: number): void;
  addDescountValue(value: number): void;
  getTaxValue(): number;
  getTotalDescount(): number;
  getAmount(): number;

  clearCart(): void;
}

const CashierContext = createContext<ICashierContextData>(
  {} as ICashierContextData
);

interface ICheckoutBox {
  CUSTOMER: {
    is_collapsed: boolean;
  };
  ADDRESS: {
    is_collapsed: boolean;
  };
  DETAILS: {
    is_collapsed: boolean;
  };
  SCHEDULE: {
    is_collapsed: boolean;
  };
  PRODUCTS: {
    is_collapsed: boolean;
  };
  DESCOUNT: {
    is_collapsed: boolean;
  };
  PAYMENT_METHOD: {
    is_collapsed: boolean;
  };
  OBSERVATIONS: {
    is_collapsed: boolean;
  };
}

const CashierProvider: React.FC<any> = ({ children }) => {
    const [checkoutBox, setCheckoutBox] = useState({
        CUSTOMER: {
            is_collapsed: true
        },
        ADDRESS: {
            is_collapsed: false
        },
        DETAILS: {
            is_collapsed: false
        },
        SCHEDULE: {
            is_collapsed: false
        },
        PRODUCTS: {
            is_collapsed: false
        },
        DESCOUNT: {
            is_collapsed: false
        },
        PAYMENT_METHOD: {
            is_collapsed: false
        },
        OBSERVATIONS: {
            is_collapsed: false
        }
    } as ICheckoutBox);

    const [products, setProducts] = useState<IProduct[]>([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalDelivery, setTotalDelivery] = useState(0);
    const [totalDescount, setTotalDescount] = useState(0);
    const [taxValue, setTaxValue] = useState(0);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        let total = 0;

        products.forEach(product => {
            total += product.price * product.quantity;
        });

        setTotalProducts(total);
        setAmount(total);
    }, [products]);

    const collapseBox = useCallback((boxName: BoxName) => {
        setCheckoutBox(oldState => {
            const newState = { ...oldState };
            // Object.keys(oldState).forEach(key => {
            //   if (!oldState[boxName].is_collapsed === false) {
            //     if (String(key) !== String(boxName)) {
            //       newState[key].is_collapsed = false;
            //     }
            //   }
            // });

            newState[boxName].is_collapsed = !newState[boxName].is_collapsed;

            return newState;
        });
    }, []);

    const getStateBox = useCallback(
        (boxName: BoxName) => {
            return checkoutBox[boxName].is_collapsed;
        },
        [checkoutBox]
    );

    const addProduct = useCallback((product: any, quantity = 1) => {
        setProducts(oldState => {
            const existProduct = oldState.find(item => item.id === product.id);

            if (existProduct) {
                return oldState.map(item => {
                    if (item.id === product.id) {
                        return {
                            ...item,
                            quantity: quantity
                        };
                    }

                    return item;
                });
            }

            const newProduct: IProduct = {
                id: product.id,
                name: product.name,
                price: product.is_promotional
                    ? product.price_promotional
                    : product.price_sale,
                quantity,
                url_thumb: product.cover.thumb_url
            };

            setCheckoutBox({
                CUSTOMER: {
                    is_collapsed: false
                },
                ADDRESS: {
                    is_collapsed: false
                },
                DETAILS: {
                    is_collapsed: false
                },
                SCHEDULE: {
                    is_collapsed: false
                },
                PRODUCTS: {
                    is_collapsed: true
                },
                DESCOUNT: {
                    is_collapsed: false
                },
                PAYMENT_METHOD: {
                    is_collapsed: false
                },
                OBSERVATIONS: {
                    is_collapsed: false
                }
            });

            return [...oldState, newProduct];
        });
    }, []);

    const removeProduct = useCallback((product_id: string) => {
        setProducts(oldState => {
            return oldState.filter(product => product.id !== product_id);
        });
    }, []);

    const getProducts = useCallback(() => {
        return products;
    }, [products]);

    const getTotalProducts = useCallback(() => {
        return totalProducts;
    }, [totalProducts]);

    const addDeliveryValue = useCallback((value: number) => {
        setTotalDelivery(value);
    }, []);

    const getTotalDelivery = useCallback(() => {
        return totalDelivery;
    }, [totalDelivery]);

    const addDescountValue = useCallback((value: number) => {
        setTotalDescount(value);
    }, []);

    const getTotalDescount = useCallback(() => {
        return totalDescount;
    }, [totalDescount]);

    const addTaxValue = useCallback((value: number) => {
        setTaxValue(value);
    }, []);

    const getTaxValue = useCallback(() => {
        return taxValue;
    }, [taxValue]);

    const getAmount = useCallback(() => {
        return (
            Number(totalProducts) +
      Number(totalDelivery) +
      Number(taxValue) -
      Number(totalDescount)
        );
    }, [totalProducts, totalDelivery, taxValue, totalDescount]);

    const clearCart = useCallback(() => {
        setProducts([]);
        setTotalProducts(0);
        setTotalDelivery(0);
        setTotalDescount(0);
        setTaxValue(0);
        setAmount(0);
    }, []);

    return (
        <CashierContext.Provider
            value={{
                collapseBox,
                getStateBox,
                addProduct,
                removeProduct,
                getProducts,
                getTotalProducts,
                addTaxValue,
                addDeliveryValue,
                getTaxValue,
                getTotalDelivery,
                addDescountValue,
                getTotalDescount,
                getAmount,
                clearCart
            }}
        >
            {children}
        </CashierContext.Provider>
    );
};

function useCashier(): ICashierContextData {
    const context = useContext(CashierContext);

    if (!context) {
        throw new Error('useCashier must be used within a CashierProvider');
    }

    return context;
}

export { CashierProvider, useCashier };
