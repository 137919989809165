import React, { useCallback, useState } from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

import { BadgeProps, Flex, PseudoBox, Text } from '@chakra-ui/core';

import TextUtils from '../../shared/utils/TextUtils';

interface IProps extends BadgeProps {
  defaultStatus: any;
  orderId: string;
  isChangeable?: boolean;
}

const STATUS_AND_COLORS = {
    AGUARDANDO_COMPROVANTE: 'blue.500',
    COMPROVANTE_EM_ANALISE: 'green.500',
    AGUARDANDO_PAGAMENTO: 'yellow.500',
    AGUARDANDO: 'yellow.500',
    APROVADO: 'green.500',
    CANCELADO: 'gray.800',
    EXTORNADO: 'purple.500',
    NEGADO: 'pink.500'
};

const status = [
    'AGUARDANDO_COMPROVANTE',
    'COMPROVANTE_EM_ANALISE',
    'AGUARDANDO_PAGAMENTO',
    'APROVADO',
    'CANCELADO',
    'EXTORNADO',
    'NEGADO'
];

const PaymentStatusSmall: React.FC<IProps> = ({
    defaultStatus,
    orderId,
    isChangeable = true,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(defaultStatus);

    const handleOnChangeStatus = useCallback(
        async (newStatus: string) => {
            setSelectedStatus(newStatus);
            setIsOpen(false);
        },
        [orderId]
    );

    return (
        <Flex width="100%" justifyContent="center" position="relative">
            <Flex
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                textAlign="center"
                height="32px"
                backgroundColor={
                    !isOpen ? STATUS_AND_COLORS[selectedStatus] : 'purple.500'
                }
                color="white"
                p="4px"
                px="8px"
                cursor={isChangeable ? 'pointer' : 'default'}
                {...rest}
                onClick={() => (isChangeable ? setIsOpen(!isOpen) : null)}
                whiteSpace="nowrap"
            >
                <Flex width="100%" justifyContent="center">
                    <Text>{TextUtils.convertStatusPaymentShort(selectedStatus)}</Text>
                </Flex>
                {isChangeable && !isOpen && <FiChevronRight />}
                {isOpen && <FiChevronDown />}
            </Flex>
            <Flex
                position="absolute"
                flexDirection="column"
                mt="32px"
                display={isOpen ? 'flex' : 'none'}
                zIndex={1000}
                backgroundColor="white"
                p="4px"
                justifyContent="space-between"
                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
            >
                {status.map(item => (
                    <PseudoBox
                        key={item}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        textAlign="center"
                        backgroundColor="gray.500"
                        color={STATUS_AND_COLORS[item]}
                        border="2px solid"
                        borderColor={STATUS_AND_COLORS[item]}
                        p="4px"
                        px="8px"
                        mb="8px"
                        {...rest}
                        onClick={() => handleOnChangeStatus(item)}
                        cursor="pointer"
                        _last={{
                            mb: 0
                        }}
                        _hover={{
                            borderColor: 'purple.500',
                            color: 'purple.500'
                        }}
                    >
                        <Text>{TextUtils.convertStatusPaymentShort(item)}</Text>
                    </PseudoBox>
                ))}
            </Flex>
        </Flex>
    );
};

export default PaymentStatusSmall;
