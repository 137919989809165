import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FiLock, FiTrash2, FiUnlock } from 'react-icons/fi';

import { Box, Divider, Flex, PseudoBox, Text } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

interface IDistrict {
  name: string;
  price: number;
  locked: boolean;
}

const DeliveryCitySettings: React.FC = () => {
    const { changeTitle } = useLayout();

    const formRef = useRef<FormHandles>(null);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [showCities, setShowCities] = useState(false);

    const handleChangeState = useCallback(stateOption => {
        const stateValue = stateOption.value;

        if (stateValue) {
            apiGateway.get(`/location/states/${stateValue}/cities`).then(response => {
                setCities(
                    response.data.map(item => {
                        if (item.is_enable) {
                            setSelectedCities(oldState => {
                                return [...oldState, item];
                            });
                        }
                        return item;
                    })
                );
                setShowCities(true);
            });
        }
    }, []);

    const handleLock = useCallback(
        city => {
            const price = formRef.current.getFieldValue(`price[${city.name}]`);

            apiGateway.put(`/settings/delivery_cities/${city.id}`, {
                price: Number(price.replace(',', '.')),
                is_enable: true
            });

            const updatedCities = selectedCities.map(item => {
                if (item.name === city.name) {
                    return {
                        ...city,
                        locked: true
                    };
                }

                return item;
            });

            setSelectedCities(updatedCities);
        },
        [selectedCities]
    );

    const handleUnLock = useCallback(
        city => {
            const updatedCities = selectedCities.map(item => {
                if (item.id === city.id) {
                    return {
                        ...city,
                        locked: false
                    };
                }
                return item;
            });
            setSelectedCities(updatedCities);
        },
        [selectedCities]
    );

    const handleClickCity = useCallback(async city => {
        const isEnable = !city.is_enable;

        if (isEnable) {
            setSelectedCities(oldState2 => {
                return [...oldState2, city];
            });
        } else {
            setSelectedCities(oldState2 => {
                return oldState2.filter(item2 => item2.id !== city.id);
            });
        }

        setCities(oldState => {
            return oldState.map(item => {
                if (item.id === city.id) {
                    return {
                        ...city,
                        is_enable: isEnable
                    };
                }

                return item;
            });
        });

        setShowCities(false);
    }, []);

    const handleDelete = useCallback(async city => {
        apiGateway
            .put(`/settings/delivery_cities/${city.id}`, {
                price: 0,
                is_enable: false
            })
            .then(response => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    setSelectedCities(oldState => {
                        return oldState.filter(item => item.id !== city.id);
                    });
                }
            });
    }, []);

    useEffect(() => {
        changeTitle('Métodos de Entrega');

        apiGateway.get('/location/states').then(response => {
            setStates(response.data);
        });
    }, [changeTitle]);

    return (
        <Form
            ref={formRef as any}
            onSubmit={() => null}
            onClick={() => setShowCities(false)}
        >
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="white"
                        px="24px"
                    >
                        <Flex
                            width="100%"
                            py="16px"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex
                                    width="50%"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Text mr="16px" fontSize="24px" color="purple.500">
                    Frete por Cidade
                                    </Text>
                                </Flex>
                            </Flex>

                            <Divider my="0px" />
                            <Flex
                                width="48%"
                                justifyContent="flex-start"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                >
                                    <Box width="48%">
                                        <Select
                                            name="state"
                                            label="Selecione o estado"
                                            onChange={e => handleChangeState(e.currentTarget)}
                                            size="sm"
                                        >
                                            <option value="">Selecione um estado</option>
                                            {states.map(state => (
                                                <option key={state.id} value={state.id}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </Box>

                                    <Flex
                                        width="48%"
                                        flexDirection="column"
                                        position="relative"
                                        onMouseEnter={() => setShowCities(true)}
                                        onMouseLeave={() => setShowCities(false)}
                                    >
                                        <Input
                                            name="city"
                                            label="Selecione a cidade"
                                            placeholder="Pesquisar cidade"
                                            size="sm"
                                            mb="4px"
                                            autoComplete="new-password"
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                        />
                                        {showCities && (
                                            <Flex
                                                width="100%"
                                                flexDirection="column"
                                                maxHeight="400px"
                                                overflowY="auto"
                                                position="absolute"
                                                top="64px"
                                                zIndex={1000000000}
                                                backgroundColor="white"
                                                border="1px solid"
                                                borderColor="gray.100"
                                            >
                                                {cities.map(city => (
                                                    <PseudoBox
                                                        display="flex"
                                                        key={city.id}
                                                        cursor="pointer"
                                                        _hover={{
                                                            color: 'purple.500'
                                                        }}
                                                        px="8px"
                                                        py="4px"
                                                        width="100%"
                                                        justifyContent="space-between"
                                                        onClick={() => handleClickCity(city)}
                                                    >
                                                        <Text fontSize="14px" fontWeight="400">
                                                            {city.name}
                                                        </Text>
                                                        {city.is_enable && (
                                                            <Box color="green.500">
                                                                <FaCheckCircle size={18} fontWeight="600" />
                                                            </Box>
                                                        )}
                                                    </PseudoBox>
                                                ))}
                                            </Flex>
                                        )}
                                    </Flex>

                                    {!!selectedCities.length && (
                                        <Flex width="100%" flexDirection="column" mt="16px">
                                            <Flex width="100%" mb="8px">
                                                <Text>Precificar Cidades</Text>
                                            </Flex>
                                            {selectedCities.map(city => (
                                                <Flex
                                                    key={city.name}
                                                    width="100%"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    mb="8px"
                                                >
                                                    <Text fontSize="14px">{city.name}</Text>
                                                    <Flex>
                                                        <Flex
                                                            width="200px"
                                                            justifyContent="center"
                                                            mr="24px"
                                                        >
                                                            <Input
                                                                name={`price[${city.name}]`}
                                                                size="sm"
                                                                mb="0px"
                                                                badge="currency"
                                                                textAlign="end"
                                                                defaultValue={String(city.price).replace(
                                                                    '.',
                                                                    ','
                                                                )}
                                                                onChange={() => handleUnLock(city)}
                                                            />
                                                        </Flex>
                                                        <Button
                                                            backgroundColor={
                                                                !!city.locked === false
                                                                    ? 'purple.500'
                                                                    : 'green.500'
                                                            }
                                                            width="32px"
                                                            maxWidth="40px"
                                                            p="0px"
                                                            fontSize="16px"
                                                            height="32px"
                                                            title={
                                                                !!city.locked === false ? 'Não Salvo' : 'Salvo'
                                                            }
                                                            _hover={{
                                                                backgroundColor:
                                  !!city.locked === false
                                      ? 'purple.500'
                                      : 'green.500'
                                                            }}
                                                            onClick={() => handleLock(city)}
                                                            _focus={{
                                                                outline: 'none'
                                                            }}
                                                        >
                                                            {!!city.locked === false && (
                                                                <FiUnlock size={16} />
                                                            )}
                                                            {!!city.locked === true && <FiLock size={16} />}
                                                        </Button>

                                                        <Button
                                                            ml="8px"
                                                            backgroundColor="pink.500"
                                                            width="32px"
                                                            maxWidth="40px"
                                                            p="0px"
                                                            fontSize="16px"
                                                            height="32px"
                                                            title="Excluir Categorias"
                                                            _hover={{
                                                                backgroundColor: 'pink.500'
                                                            }}
                                                            _focus={{
                                                                outline: 'none'
                                                            }}
                                                            onClick={() => handleDelete(city)}
                                                        >
                                                            <FiTrash2 size={16} />
                                                        </Button>
                                                    </Flex>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider my="0px" />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default DeliveryCitySettings;
