import { ValidationError } from 'yup';

export interface IErrors {
  [key: string]: string;
}

export default class ValidationUtils {
    static getValidationErrors(err: ValidationError): IErrors {
        const validationErrors: IErrors = {};

        err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message;
        });

        return validationErrors;
    }
}
