import React, { useRef, useEffect, memo } from 'react';

import {
    Flex,
    Box,
    Select as ChakraSelect,
    Text,
    SelectProps
} from '@chakra-ui/core';
import { useField } from '@unform/core';

interface IProps extends SelectProps {
  name: string;
  label?: string;
  isRequired?: boolean;
  maxWidth?: string;
  mb?: string | number;
}

const Select: React.FC<IProps> = ({
    name,
    label = null,
    children,
    isRequired,
    maxWidth,
    mb = '16px',
    ...rest
}) => {
    const inputRef = useRef<HTMLSelectElement>(null);

    const { fieldName, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    return (
        <Box width="100%" maxWidth={maxWidth} fontWeight="500" mb={mb}>
            {label && (
                <Flex width="100%" textAlign="center" mb="4px">
                    <Text>{label}</Text>
                    {isRequired && (
                        <Text ml="8px" color="red.500">
              *
                        </Text>
                    )}
                </Flex>
            )}

            <ChakraSelect
                ref={inputRef}
                _focus={{
                    outline: 'none'
                }}
                name={name}
                isInvalid={!!error}
                {...rest}
            >
                {children}
            </ChakraSelect>
        </Box>
    );
};

export default memo(Select);
