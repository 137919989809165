export const HTTP = {
    STATUS: {
        SUCCESS: 200,
        CREATED: 201,
        BAD_REQUEST: 400,
        NOT_AUTORIZED: 401, 
        INTERNAL_ERROR: 500
    }
};

export const PAY3_LINK_URL = process.env.REACT_APP_PAY3_LINK_URL;
