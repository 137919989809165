import FormHelper from '../../../shared/helpers/FormHelper';
import ProductMap from '../mappers/ProductMap';
import ProductValidator from '../validators/ProductValidator';

export default class ProductFormHelper extends FormHelper {
    public static productData: any;

    static async makeValidProductDataToSend({
        formData,
        is_enable,
        is_promotional,
        is_free_shipping,
        tags,
        images,
        categories,
        types,
        subtypes,
        cover_id,
        stock
    }) {
        const data = formData;

        data.is_enable = is_enable;
        data.is_promotional = is_promotional;
        data.is_free_shipping = is_free_shipping;
        data.tags = tags;
        data.images = images;
        data.categories = categories;
        data.types = types;
        data.subtypes = subtypes;
        data.cover_id = cover_id;

        data.stock = stock;

        this.productData = ProductMap.formDataToCreateDTO(data);

        await ProductValidator.check(this.productData);

        return this.productData;
    }

    static resetStatesForm({
        setEnable,
        setPromotional,
        setFreeShipping,
        setImages,
        setCategories,
        setTypes,
        setSubtypes,
        setTags,
        setDefaultMultiSelect = undefined
    }): void {
        setEnable(false);
        setPromotional(false);
        setFreeShipping(false);
        setImages([]);
        setCategories([]);
        setTypes([], []);
        setSubtypes([], []);
        setTags([], []);

        if (setDefaultMultiSelect) {
            setDefaultMultiSelect([]);
        }
    }
}
