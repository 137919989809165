import React from 'react';

import { Button, Flex, Text } from '@chakra-ui/core';

interface IProps {
  code: string;
  showConfirmation: boolean;
  confirmationFunction: (...args: any) => any;
  cancelFunction: (...args: any) => any;
}

const ConfirmationPanel: React.FC<IProps> = ({
    code,
    showConfirmation,
    confirmationFunction,
    cancelFunction
}) => {
    return (
        <Flex
            display={showConfirmation ? 'flex' : 'none'}
            width="100%"
            border="2px solid"
            borderColor="yellow.500"
            backgroundColor="yellow.100"
            alignItems="center"
            px="8px"
            py="8px"
            mt="24px"
        >
            <Flex width="50%" px="8px">
                <Text fontSize="24px" color="purple.500">
                    {`Deseja alterar o status do pedido: ${code}?`}
                </Text>
            </Flex>

            <Flex width="50%" color="white" justifyContent="flex-end">
                <Button
                    borderRadius="2px"
                    width="200px"
                    backgroundColor="purple.500"
                    _hover={{
                        backgroundColor: 'purple.500'
                    }}
                    mr="8px"
                    onClick={confirmationFunction}
                    outline="none"
                    _focus={{
                        outline: 'none'
                    }}
                >
          SIM
                </Button>
                <Button
                    borderRadius="2px"
                    width="200px"
                    backgroundColor="red.500"
                    _hover={{
                        backgroundColor: 'red.500'
                    }}
                    outline="none"
                    onClick={() => cancelFunction(false)}
                    _focus={{
                        outline: 'none'
                    }}
                >
          NÃO
                </Button>
            </Flex>
        </Flex>
    );
};

export default ConfirmationPanel;
