import React, { useCallback, useState } from 'react';

import Input from '../../../../../../../components/Form/Input';
import Select from '../../../../../../../components/Form/Select';
import { useCashier } from '../../../../../hooks/useCashier';

interface DeliveryNeighborhoodProps {
  deliveryMethodType: 'CITY' | 'DISTRICT';
  neighborhoods: any[];
  onChange: any;
}

const DeliveryNeighborhoodFields: React.FC<DeliveryNeighborhoodProps> = ({
    deliveryMethodType,
    neighborhoods,
    onChange
}) => {
    const { addDeliveryValue } = useCashier();

    const [neighborhoodId, setNeighborhoodId] = useState('');

    const handleChangeDeliveryNeighborhood = useCallback(
        e => {
            const shippingValue =
        e.target[e.target.selectedIndex].getAttribute('data-value');

            const shippingId =
        e.target[e.target.selectedIndex].getAttribute('data-id');

            setNeighborhoodId(shippingId);

            if (shippingValue) {
                addDeliveryValue(Number(shippingValue));
            }
        },
        [addDeliveryValue]
    );

    if (deliveryMethodType === 'CITY') {
        return (
            <Input
                name="address_neighborhood"
                backgroundColor="gray.500"
                borderRadius="2px"
                size={'sm'}
                placeholder="Bairro"
                onChange={() => onChange()}
            />
        );
    }

    return (
        <Select
            name="address_neighborhood"
            backgroundColor="gray.500"
            borderRadius="2px"
            size="sm"
            onChange={e => {
                handleChangeDeliveryNeighborhood(e);
                onChange();
            }}
        >
            <option value="">Selecione o bairro</option>
            {neighborhoods.map(neighborhood => (
                <option
                    key={neighborhood.id}
                    data-id={neighborhood.id}
                    data-value={Number(neighborhood.price)}
                >
                    {neighborhood.name}
                </option>
            ))}
            <Input
                typeInput="hidden"
                name="neighborhood_id"
                value={neighborhoodId}
            />
        </Select>
    );
};

export default DeliveryNeighborhoodFields;
