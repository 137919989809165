/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';

import SelectMultiple from '../../../../components/Form/SelectMultiple';
import { useProductForm } from '../../contexts/ProductFormProvider';
import ProductFormHelper from '../../helpers/ProductFormHelper';

interface IProps {
  defaultMultiSelect?: any[];
}

const SelectSubtypes = ({ defaultMultiSelect }: IProps) => {
    const { addSubtypes } = useProductForm();

    const handleSetTypes = useCallback(
        (ids: string[], news: string[]): void => {
            addSubtypes(ids, news);
        },
        [addSubtypes]
    );

    const handleOnSearchTypes = useCallback(
        async (query: string): Promise<any> => {
            return ProductFormHelper.searchResultMultipleSelect(
                query,
                '/products/subtypes',
                false,
                0
            );
        },
        []
    );

    return (
        <SelectMultiple
            name="types"
            label="Categoria do Monte sua Cesta"
            size="sm"
            placeholder="Pesquisar categoria"
            onSearch={handleOnSearchTypes}
            onSetItems={handleSetTypes}
            defaultValues={defaultMultiSelect}
        />
    );
};

export default SelectSubtypes;
