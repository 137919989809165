import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Flex,
    Image,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast
} from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  productsIds: string[];
}

const ModalPrices: React.FC<IProps> = ({ isOpen, onClose, productsIds }) => {
    const [products, setProducts] = useState([]);

    const [typeValue, setTypeValue] = useState('PERCENTUAL');
    const [typeCalc, setTypeCalc] = useState('INCREASE');
    const [isAjustedValues, setIsAjustedValues] = useState(false);
    const [value, setValue] = useState(0);

    const formRef = useRef<FormHandles>(null);

    const toast = useToast();
    const navigate = useNavigate();

    const handleAdjustValue = useCallback(() => {
        const valueRef = formRef.current.getFieldValue('value');

        if (valueRef && valueRef !== '0') {
            setValue(Number(valueRef));
            setIsAjustedValues(true);
        } else {
            toast({
                title: 'Valor Inválido',
                description: 'Informe um valor válido',
                status: 'error',
                duration: 6000,
                isClosable: true
            });
        }
    }, [toast]);

    const calcValue = useCallback(
        (originalValue: string) => {
            if (typeValue === 'PERCENTUAL') {
                return Math.abs(
                    Number(originalValue) *
            (value / 100 + (typeCalc === 'INCREASE' ? 1 : -1))
                );
            }
            return Number(originalValue) + (typeCalc === 'INCREASE' ? value : -value);
        },
        [typeCalc, typeValue, value]
    );

    const handleConfirm = useCallback(() => {
        apiGateway
            .put('/products/by_ids', {
                ids: productsIds,
                typeCalc,
                typeValue,
                value
            })
            .then(response => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    toast({
                        title: 'Preços alterados',
                        description: 'Preços alterados com sucesso',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    onClose();
                    navigate('/products');
                }
            });
    }, [navigate, onClose, productsIds, toast, typeCalc, typeValue, value]);

    useEffect(() => {
        if (productsIds.length > 0) {
            apiGateway
                .get('/products/by_ids', {
                    params: {
                        ids: productsIds
                    }
                })
                .then(response => {
                    setProducts(response.data);
                });
        }
    }, [productsIds]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="500">Modificar Preços</ModalHeader>
                    <ModalCloseButton />

                    <Flex width="100%" px="24px" flexDirection="column">
                        <Text mb="8px" fontSize="16px" fontWeight="500">
              Alterar o preço do seguintes produtos:
                        </Text>

                        <Flex
                            width="100%"
                            flexDirection="column"
                            height="320px"
                            overflow="auto"
                            zIndex={10000}
                        >
                            {products.map((product, index) => (
                                <Flex
                                    key={product.id}
                                    justifyContent="space-between"
                                    width="100%"
                                    fontSize="12px"
                                    backgroundColor={index % 2 === 0 ? 'gray.100' : 'white'}
                                    alignItems="center"
                                >
                                    <Flex alignItems="center">
                                        <Image
                                            src={product.cover.thumb_url}
                                            width="40px"
                                            height="40px"
                                            mr="16px"
                                        />

                                        <Text color="purple.500" fontWeight="500">
                                            {product.name}
                                        </Text>
                                    </Flex>
                                    <Flex>
                                        <Text
                                            mr="8px"
                                            color={isAjustedValues ? 'red.500' : 'gray.800'}
                                            fontWeight="600"
                                        >
                                            {CurrencyUtils.numberToCurrency(product.price_sale)}
                                        </Text>
                                        {isAjustedValues && (
                                            <Text mr="8px" color="green.500" fontWeight="600">
                                                {CurrencyUtils.numberToCurrency(
                                                    calcValue(product.price_sale)
                                                )}
                                            </Text>
                                        )}
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>

                        <Flex
                            width="100%"
                            flexDirection="row"
                            mt="16px"
                            alignItems="center"
                            fontWeight="500"
                        >
                            <Flex flexDirection="column" mr="24px" fontSize="12px">
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="160px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_free_shipping"
                                        isChecked={typeCalc === 'REDUCE'}
                                        onChange={() =>
                                            setTypeCalc(typeCalc === 'REDUCE' ? 'INCREASE' : 'REDUCE')
                                        }
                                        value="0"
                                        mr="16px"
                                        size="sm"
                                    >
                                        <Text fontSize="12px">Reduzir</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_free_shipping"
                                        isChecked={typeCalc === 'INCREASE'}
                                        onChange={() =>
                                            setTypeCalc(
                                                typeCalc === 'INCREASE' ? 'REDUCE' : 'INCREASE'
                                            )
                                        }
                                        value="1"
                                        mr="16px"
                                        size="sm"
                                    >
                                        <Text fontSize="12px">Aumentar</Text>
                                    </Checkbox>
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="160px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_free_shipping"
                                        isChecked={typeValue === 'FIXED'}
                                        onChange={() =>
                                            setTypeValue(
                                                typeValue === 'FIXED' ? 'PERCENTUAL' : 'FIXED'
                                            )
                                        }
                                        value="0"
                                        mr="16px"
                                        size="sm"
                                    >
                                        <Text fontSize="12px">Fixo</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_free_shipping"
                                        isChecked={typeValue === 'PERCENTUAL'}
                                        onChange={() =>
                                            setTypeValue(
                                                typeValue === 'PERCENTUAL' ? 'FIXED' : 'PERCENTUAL'
                                            )
                                        }
                                        value="1"
                                        mr="16px"
                                        size="sm"
                                    >
                                        <Text fontSize="12px">Percentual</Text>
                                    </Checkbox>
                                </Flex>
                            </Flex>

                            <Form ref={formRef as any} onSubmit={() => null}>
                                <Flex width="200px" mt="8px" fontSize="12px">
                                    <Input
                                        fontSize="12px"
                                        label="Valor a ser modificado"
                                        name="value"
                                        defaultValue="0"
                                        size="sm"
                                        textAlign="right"
                                        badge={
                                            typeValue === 'PERCENTUAL' ? 'porcentage' : 'currency'
                                        }
                                    />
                                </Flex>
                            </Form>
                        </Flex>
                    </Flex>

                    {isAjustedValues && (
                        <Flex
                            width="100%"
                            px="24px"
                            backgroundColor="red.100"
                            borderColor="red.500"
                            py="8px"
                        >
                            <Text lineHeight="20px" fontSize="16px" fontWeight="500">
                Atenção: ao confirmar todos os produtos acima teram seus valores
                alterados, essa ação é irreversível.
                            </Text>
                        </Flex>
                    )}

                    <ModalFooter fontWeight="500" color="white">
                        <Button
                            borderRadius="2px"
                            fontSize="14px"
                            backgroundColor="red.500"
                            mr={3}
                            onClick={onClose}
                            py="8px"
                            _hover={{
                                backgroundColor: 'red.500'
                            }}
                        >
              Cancelar
                        </Button>
                        <Button
                            borderRadius="2px"
                            fontSize="14px"
                            backgroundColor="purple.500"
                            mr={3}
                            py="8px"
                            _hover={{
                                backgroundColor: 'purple.500'
                            }}
                            onClick={() => handleAdjustValue()}
                        >
              Ajustar Valores
                        </Button>
                        <Button
                            borderRadius="2px"
                            fontSize="14px"
                            backgroundColor={isAjustedValues ? 'green.500' : 'gray.800'}
                            py="8px"
                            isDisabled={!isAjustedValues}
                            _hover={{
                                backgroundColor: isAjustedValues ? 'green.500' : 'gray.800'
                            }}
                            onClick={() => handleConfirm()}
                        >
              Confirmar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalPrices;
