import React from 'react';
import { FiLink } from 'react-icons/fi';

import { Box, Flex, Image, Text } from '@chakra-ui/core';

import ProductHelper from '../../../../shared/helpers/ProductHelper';

interface IProps {
  product: {
    name: string;
    code_sku: string;
    cover: {
      thumb_url: string;
    };
  };
}
const ProductsResults: React.FC<IProps> = ({ product }) => {
    return (
        <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Box width="80px" height="80px">
                <Image src={product.cover.thumb_url} />
            </Box>

            <Flex flexDirection="column" fontSize="12px">
                <Text>{product.code_sku}</Text>
            </Flex>
            <Flex flexDirection="column" fontSize="12px">
                <Text>{product.name}</Text>
                <Text color="pink.500">{ProductHelper.getCorrectPrice(product)}</Text>
            </Flex>

            <FiLink size={16} />
        </Flex>
    );
};

export default ProductsResults;
