import React, { useCallback, useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { FiSearch, FiShoppingBag } from 'react-icons/fi';
import { Divider, Flex, Image, Text } from '@chakra-ui/core';
import Form from '../../../../../../components/Form';
import Input from '../../../../../../components/Form/Input';
import CurrencyUtils from '../../../../../../shared/utils/CurrencyUtils';
import { useCashier } from '../../../../hooks/useCashier';
import apiGateway from '../../../../../../shared/services/apiGateway';

const Catalog: React.FC<any> = ({ formRef }) => {
    const { addProduct } = useCashier();
    const [products, setProducts] = useState([] as any);
    const [isFocus, setIsFocus] = useState(false);

    const loadHighlights = useCallback(() => {
        apiGateway.get('/products?highlight=true')
            .then(response => {
                setProducts(response.data);
            });
    }, []);

    const handleSearchProducts = useCallback(() => {
        const value = formRef.current.getFieldValue('search');
        if (value?.length > 3) {
            const clearQuery = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            apiGateway
                .get('/products', {
                    params: {
                        query: clearQuery
                    }
                })
                .then(response => {
                    setProducts(response.data);
                });
        }

        if(!value || value.length <= 0) 
            loadHighlights();
    }, []);

    useEffect(() => {
        formRef.current.getFieldRef('search').value = '';
        loadHighlights();
    }, []);

    return (
        <Form
            style={{ width: '100%', height: '100%' }}
            ref={formRef as any}
            onSubmit={() => undefined}
        >
            <Flex
                width="100%"
                height="100%"
                maxHeight={'100%'}
                flexDirection="column"
                border="1px solid"
                borderColor="gray.100"
                px="16px"
            >
                <Flex width="100%" flexDirection="column" py="8px">
                    <Text color="purple.500" fontSize="20px" mb="8px" fontWeight={'500'}>
            Pesquisar Produtos
                    </Text>
                    <Flex width="100%">
                        <Flex width="100%" pr="8px">
                            <Input
                                name="search"
                                size="sm"
                                placeholder="Por nome ou código"
                                mb="8px"
                                onChange={() => handleSearchProducts()}
                                autoComplete="off"
                                onFocusCapture={() => setIsFocus(true)}
                                onBlur={() => setIsFocus(false)}
                                border={isFocus ? '4px solid' : '2px solid'}
                                borderColor={isFocus ? 'purple.500' : 'gray.200'}
                            />
                        </Flex>
                        <Flex
                            width="40px"
                            height="32px"
                            backgroundColor="purple.500"
                            borderRadius="2px"
                            color="white"
                            justifyContent="center"
                            alignItems="center"
                            cursor="pointer"
                            onClick={() => handleSearchProducts()}
                        >
                            <FiSearch />
                        </Flex>
                    </Flex>
                    <Flex
                        width="100%"
                        height={'244px'}
                        flexDirection="column"
                        overflow="auto"
                    >
                        {products.map(product => (
                            <Flex key={product.id} width="100%" flexDirection="column">
                                <Flex width="100%" alignItems="center" cursor="pointer">
                                    <Flex width="80px" height="80px" mr="8px">
                                        <Image src={product.cover.thumb_url} />
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <Text fontSize="12px" float="left">
                                            {product.name}
                                        </Text>
                                        <Text fontSize="12px" color="pink.500">
                                            {CurrencyUtils.numberToCurrency(
                                                product.is_promotional
                                                    ? product.price_promotional
                                                    : product.price_sale
                                            )}
                                        </Text>
                                    </Flex>

                                    <Flex ml="auto" alignItems="center">
                                        <Flex
                                            mr="16px"
                                            onClick={() => addProduct(product)}
                                            backgroundColor="green.500"
                                            color="white"
                                            borderRadius="2px"
                                            px="8px"
                                            py="4px"
                                            alignItems="center"
                                        >
                                            <Text fontSize="12px" mr="8px" whiteSpace={'nowrap'}>
                        Add
                                            </Text>
                                            <FiShoppingBag size={16} />
                                        </Flex>

                                        <Flex color="green.500">
                                            <a
                                                href={`https://wa.me/?text=${product.name}%0A%0D${
                                                    product.description
                                                }%0A%0DValor ${CurrencyUtils.numberToCurrency(
                                                    product.is_promotional
                                                        ? product.price_promotional
                                                        : product.price_sale
                                                )}`}
                                                target="_blank" rel="noreferrer"
                                            >
                                                <FaWhatsapp size={24} />
                                            </a>
                                        </Flex>
                                    </Flex>

                                    <Divider
                                        mt="8px"
                                        border="1px solid"
                                        borderColor="green.500"
                                    />
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default Catalog;
