import { motion, Variants } from 'framer-motion';
import React, { useState } from 'react';

import { Flex, Box, Image } from '@chakra-ui/core';

// import { Container } from './styles';

interface IPictureZoomProps {
  img: string;
  alt?: string;
  width: any;
  height: any;
}

const PictureZoom: React.FC<IPictureZoomProps> = ({
    img,
    alt,
    width,
    height
}) => {
    const [isHovered, setIsHover] = useState(false);

    const variants: Variants = {
        zoomIn: {
            scale: 4,
            margin: 'auto'
        },

        zoomOut: {
            scale: 1
        }
    };

    return (
        <Flex
            width="100%"
            height="100%"
            position={isHovered ? 'fixed' : 'initial'}
            top="0"
            left="0"
            onClick={() => setIsHover(!isHovered)}
            zIndex={1000000}
            cursor="pointer"
            style={{
                backdropFilter: isHovered ? 'blur(8px)' : 'none'
            }}
        >
            <motion.div
                variants={variants}
                initial="zoomOut"
                style={{
                    width,
                    height
                }}
                animate={isHovered ? 'zoomIn' : 'zoomOut'}
            >
                <Box width="100%" height="100%" position="relative">
                    <Image src={img} alt={alt} />
                </Box>
            </motion.div>
        </Flex>
    );
};

export default PictureZoom;
