import React, { useCallback, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';

import { Collapse, Flex, Text } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Button from '../../../../../../components/Button';
import Checkbox from '../../../../../../components/Form/Checkbox';
import Input from '../../../../../../components/Form/Input';
import Select from '../../../../../../components/Form/Select';
import { HTTP } from '../../../../../../shared/constants';
import apiViaCEP from '../../../../../../shared/services/apiViaCEP';
import CollapseCheckoutBox from '../../../../components/CollapseCheckoutBox';
import { useCashier } from '../../../../hooks/useCashier';
import DeliveryNeighborhoodFields from './DeliveryNeighborhoodFields';
import apiGateway from '../../../../../../shared/services/apiGateway';

interface DeliveryNeighborhood {
  id: string;
  name: string;
  price: number;
}

interface DeliveryCity {
  name: string;
  price: number;
  neighborhoods: DeliveryNeighborhood[];
}

interface SetupCheckout {
  deliveryMethod: {
    type: 'CITY' | 'DISTRICT';
    name: string;
  };
  deliveryCities: DeliveryCity[];
}

interface IProps {
  formRef: React.MutableRefObject<FormHandles>;
  handleSetInStore: any;
}

const AddressSection: React.FC<IProps> = ({ formRef, handleSetInStore }) => {
    const { getStateBox, collapseBox, addDeliveryValue } = useCashier();

    const [deliverySettings, setDeliverySettings] = useState<SetupCheckout>(
    null as any
    );

    const [selectedCity, setSelectedCity] = useState(null as any);

    const [deliveryInStore, setDeliveryInStore] = useState(false);

    const [sectionStatus, setSectionStatus] = useState({
        isSuccess: false,
        isError: false
    });

    const handleSetDeliveryInStore = useCallback(value => {
        if (value) {
            setSectionStatus({
                isSuccess: true,
                isError: false
            });
        }

        setDeliveryInStore(value);
    }, []);

    const handleRequiredFields = useCallback(() => {
        const value1 = formRef.current.getFieldValue('address_city');
        const value2 = formRef.current.getFieldValue('address_neighborhood');
        const value3 = formRef.current.getFieldValue('address_street');
        const value4 = formRef.current.getFieldValue('address_number');

        if (value1 && value2 && value3 && value4) {
            formRef.current.setFieldError('address_city', '');
            formRef.current.setFieldError('address_neighborhood', '');
            formRef.current.setFieldError('address_street', '');
            formRef.current.setFieldError('address_number', '');

            setSectionStatus({
                isSuccess: true,
                isError: false
            });
        } else {
            setSectionStatus({
                isSuccess: false,
                isError: false
            });
        }
    }, []);

    const handleSearchCEP = useCallback(async () => {
        const cep = formRef.current.getFieldValue('address_cep');

        const { data } = await apiViaCEP.get(`/${cep}/json`);

        if (!data.erro) {
            formRef.current.getFieldRef('address_city').value = data.localidade;
            formRef.current.getFieldRef('address_neighborhood').value = data.bairro;
            formRef.current.getFieldRef('address_street').value = data.logradouro;
            formRef.current.getFieldRef('address_number').value = '';
            formRef.current.getFieldRef('address_complement').value = '';

            setSectionStatus({
                isSuccess: true,
                isError: false
            });
        }
    }, [formRef]);

    const handleDeliveryCity = useCallback(
        (value: string) => {
            const city = deliverySettings.deliveryCities.find(
                item => item.name === value
            );

            if (city) {
                const citySorted = city;
                citySorted.neighborhoods = city.neighborhoods.sort((a, b) =>
                    b.name
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()
                        .replace(/ /g, '') 
                        > a.name
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                            .replace(/ /g, '')
                        ? -1
                        : 1
                );

                setSelectedCity(citySorted);

                if (deliverySettings.deliveryMethod.type === 'CITY') {
                    addDeliveryValue(Number(city.price));
                }
            }
        },
        [addDeliveryValue, deliverySettings]
    );

    useEffect(() => {
        Promise.all([
            apiGateway.get('/settings/delivery_methods/enabled'),
            apiGateway.get('/settings/delivery_cities/enabled')
        ]).then((response) => {
            const [{ data: deliveryMethod }, { data: deliveryCities }] =  response;
            setDeliverySettings({ deliveryMethod, deliveryCities });
        });
    }, []);

    return (
        <Flex width={'100%'} flexDirection="column">
            <CollapseCheckoutBox
                title="Endereço de Entrega"
                isCollapsed={getStateBox('ADDRESS')}
                onCollapse={() => collapseBox('ADDRESS')}
                isComplete={sectionStatus.isSuccess}
            />

            <Collapse isOpen={getStateBox('ADDRESS')} pt="8px">
                <Flex width="100%" mb="8px" px="8px">
                    <Checkbox
                        width="100%"
                        name="is_enable"
                        isChecked={deliveryInStore}
                        onChange={() => {
                            handleSetDeliveryInStore(!deliveryInStore);
                            handleSetInStore(!deliveryInStore);
                        }}
                        value="0"
                        size="sm"
                        mr="16px"
                    >
                        <Text fontSize="14px" width="100%">
              Retirar na Loja
                        </Text>
                    </Checkbox>
                </Flex>

                {deliveryInStore === false && (
                    <Flex width={'100%'} flexDirection="column">
                        <Select
                            name="address_city"
                            backgroundColor="gray.500"
                            borderRadius="2px"
                            label=""
                            size="sm"
                            onChange={e => {
                                handleDeliveryCity(e.currentTarget.value);
                                handleRequiredFields();
                            }}
                        >
                            <option value="">Selecione a cidade</option>
                            {deliverySettings &&
                                deliverySettings.deliveryCities.map(city => (
                                    <option key={city.name} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                        </Select>
                             
                        <Input
                            typeInput="hidden"
                            name="city_id"
                            value={selectedCity?.id}
                        />        
                        <DeliveryNeighborhoodFields
                            deliveryMethodType={
                                deliverySettings
                                    ? deliverySettings.deliveryMethod.type
                                    : 'DISTRICT'
                            }
                            neighborhoods={selectedCity ? selectedCity.neighborhoods : []}
                            onChange={() => handleRequiredFields()}
                        />

                        <Input
                            name="address_street"
                            backgroundColor="gray.500"
                            placeholder="Rua"
                            borderRadius="2px"
                            size="sm"
                            mb="8px"
                            onBlur={() => handleRequiredFields()}
                        />

                        <Input
                            name="address_number"
                            backgroundColor="gray.500"
                            placeholder="Número"
                            borderRadius="2px"
                            size="sm"
                            mb="8px"
                            onBlur={() => handleRequiredFields()}
                        />

                        <Input
                            name="address_complement"
                            backgroundColor="gray.500"
                            placeholder="Complemento"
                            borderRadius="2px"
                            size="sm"
                        />

                        <Flex width="100%">
                            <Input
                                name="address_cep"
                                backgroundColor="gray.500"
                                placeholder="CEP"
                                borderRadius="2px"
                                size="sm"
                                mb="8px"
                                mask="99999-999"
                                onEnterPress={() => handleSearchCEP()}
                            />

                            <Button
                                backgroundColor="green.500"
                                borderRadius="2px"
                                width="40px"
                                size="sm"
                                height="32px"
                                p="0px"
                                fontSize="12px"
                                display="flex"
                                alignItems="center"
                                onClick={() => handleSearchCEP()}
                                _focus={{
                                    outline: 'none'
                                }}
                            >
                                <FiSearch size={16} />
                            </Button>
                        </Flex>
                    </Flex>
                )}
            </Collapse>
        </Flex>
    );
};

export default AddressSection;
