import React from 'react';
import { FcExpired } from 'react-icons/fc';

import { Flex, Text } from '@chakra-ui/core';

const ChatList: React.FC = () => {
    return (
        <Flex
            width={'36%'}
            height="calc(100vh - 48px)"
            flexDirection="column"
            backgroundColor={'gray.100'}
        >
            <Flex
                width={'100%'}
                height="100%"
                flexDirection={'column'}
                justifyContent="center"
                alignItems={'center'}
            >
                <FcExpired size={80} />
                <Text fontWeight={'500'} fontSize="24px" mt="16px" color="gray.800">
          Chat em Construção
                </Text>
            </Flex>
        </Flex>
    );
};

export default ChatList;
