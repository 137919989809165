import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiLock, FiPlus, FiTrash2, FiUnlock } from 'react-icons/fi';

import { Box, Divider, Flex, Text } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

interface IDistrict {
  id: string;
  name: string;
  price: number;
  locked: boolean;
}

const DeliveryDistrictSettings: React.FC = () => {
    const { changeTitle } = useLayout();

    const formRef = useRef<FormHandles>(null);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState<IDistrict[]>([]);
    const [selectedCity, setSelectedCity] = useState(null);

    const handleChangeState = useCallback(stateOption => {
        const stateValue = stateOption.value;

        if (stateValue) {
            apiGateway.get(`/location/states/${stateValue}/cities`).then(response => {
                setCities(response.data);
            });
        }
    }, []);

    const handleChangeCity = useCallback(cityOption => {
        const cityValue = cityOption.value;

        if (cityValue) {
            const index = cityOption.selectedIndex;
            const optionElement = cityOption.childNodes[index];
            const city = JSON.parse(optionElement.getAttribute('data-city'));

            setSelectedCity(city);

            apiGateway
                .get(`/settings/delivery_cities/${city.id}/delivery_districts`)
                .then(response => {
                    setDistricts(
                        response.data.map(item => {
                            return {
                                ...item,
                                locked: true
                            };
                        })
                    );
                });
        }
    }, []);

    const handleAddNewDistrict = useCallback(() => {
        const newDistrictRef = formRef.current.getFieldRef('new_district');

        if (newDistrictRef) {
            const newDistrict = {
                name: newDistrictRef.value,
                price: '0.00',
                delivery_city_id: selectedCity.id
            };

            apiGateway
                .post('/settings/delivery_districts', newDistrict)
                .then(response => {
                    if (response.status === HTTP.STATUS.CREATED) {
                        setDistricts((oldState: any) => {
                            return [
                                {
                                    id: response.data.id,
                                    name: newDistrict.name,
                                    price: newDistrict.price,
                                    locked: true
                                },
                                ...oldState
                            ];
                        });
                    }

                    newDistrictRef.value = '';
                });
        }
    }, [selectedCity]);

    const handleLock = useCallback(
        district => {
            const price = formRef.current.getFieldValue(`price[${district.name}]`);

            apiGateway.put(`/settings/delivery_districts/${district.id}`, {
                price: Number(price.replace(',', '.'))
            });

            const updatedDistricts = districts.map(item => {
                if (item.name === district.name) {
                    return {
                        ...district,
                        locked: true
                    };
                }

                return item;
            });

            setDistricts(updatedDistricts);
        },
        [districts]
    );

    const handleUnLock = useCallback(
        district => {
            const updatedDistricts = districts.map(item => {
                if (item.name === district.name) {
                    return {
                        ...district,
                        locked: false
                    };
                }

                return item;
            });

            setDistricts(updatedDistricts);
        },
        [districts]
    );

    const handleDelete = useCallback(async district => {
        apiGateway
            .delete('/settings/delivery_districts', {
                params: {
                    ids: [district.id]
                }
            })
            .then(response => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    setDistricts(oldState => {
                        return oldState.filter(item => item.id !== district.id);
                    });
                }
            });
    }, []);

    useEffect(() => {
        changeTitle('Métodos de Entrega');

        apiGateway.get('/location/states').then(response => {
            setStates(response.data);
        });
    }, [changeTitle]);

    return (
        <Form ref={formRef as any} onSubmit={() => null}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="white"
                        px="24px"
                    >
                        <Flex
                            width="100%"
                            py="16px"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex
                                    width="50%"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Text mr="16px" fontSize="24px" color="purple.500">
                    Frete por bairros
                                    </Text>
                                </Flex>
                            </Flex>

                            <Divider my="0px" />
                            <Flex
                                width="48%"
                                justifyContent="flex-start"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                >
                                    <Box width="48%">
                                        <Select
                                            name="state"
                                            label="Selecione o estado"
                                            onChange={e => handleChangeState(e.currentTarget)}
                                            size="sm"
                                        >
                                            <option value="">Selecione um estado</option>
                                            {states.map(state => (
                                                <option key={state.id} value={state.id}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </Box>

                                    <Box width="48%">
                                        <Select
                                            name="city"
                                            label="Selecione a cidade"
                                            onChange={e => handleChangeCity(e.currentTarget)}
                                            size="sm"
                                        >
                                            <option>Selecione uma cidade</option>
                                            {cities.map(city => (
                                                <option
                                                    key={city.id}
                                                    value={city.name}
                                                    data-city={JSON.stringify(city)}
                                                >
                                                    {city.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </Box>

                                    {!!districts.length && (
                                        <Flex width="100%" flexDirection="column" mt="16px">
                                            <Flex width="100%" mb="8px">
                                                <Text>Precificar bairros</Text>
                                            </Flex>
                                            {districts.map(district => (
                                                <Flex
                                                    key={district.id}
                                                    width="100%"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    mb="8px"
                                                >
                                                    <Text fontSize="14px">{district.name}</Text>
                                                    <Flex>
                                                        <Flex
                                                            width="200px"
                                                            justifyContent="center"
                                                            mr="24px"
                                                        >
                                                            <Input
                                                                name={`price[${district.name}]`}
                                                                size="sm"
                                                                mb="0px"
                                                                badge="currency"
                                                                textAlign="end"
                                                                defaultValue={String(district.price).replace(
                                                                    '.',
                                                                    ','
                                                                )}
                                                                onChange={() => handleUnLock(district)}
                                                            />
                                                        </Flex>
                                                        <Button
                                                            backgroundColor={
                                                                !!district.locked === false
                                                                    ? 'purple.500'
                                                                    : 'green.500'
                                                            }
                                                            width="32px"
                                                            maxWidth="40px"
                                                            p="0px"
                                                            fontSize="16px"
                                                            height="32px"
                                                            title={
                                                                !!district.locked === false
                                                                    ? 'Não Salvo'
                                                                    : 'Salvo'
                                                            }
                                                            _hover={{
                                                                backgroundColor:
                                  !!district.locked === false
                                      ? 'purple.500'
                                      : 'green.500'
                                                            }}
                                                            onClick={() => handleLock(district)}
                                                            _focus={{
                                                                outline: 'none'
                                                            }}
                                                        >
                                                            {!!district.locked === false && (
                                                                <FiUnlock size={16} />
                                                            )}
                                                            {!!district.locked === true && (
                                                                <FiLock size={16} />
                                                            )}
                                                        </Button>

                                                        <Button
                                                            ml="8px"
                                                            backgroundColor="pink.500"
                                                            width="32px"
                                                            maxWidth="40px"
                                                            p="0px"
                                                            fontSize="16px"
                                                            height="32px"
                                                            title="Excluir Categorias"
                                                            _hover={{
                                                                backgroundColor: 'pink.500'
                                                            }}
                                                            _focus={{
                                                                outline: 'none'
                                                            }}
                                                            onClick={() => handleDelete(district)}
                                                        >
                                                            <FiTrash2 size={16} />
                                                        </Button>
                                                    </Flex>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                            {selectedCity && (
                                <Flex
                                    width="48%"
                                    justifyContent="flex-start"
                                    flexDirection="column"
                                >
                                    <Flex width="100%">
                                        <Text>{`Cadastrar novo bairro em ${selectedCity.name}`}</Text>
                                    </Flex>
                                    <Flex width="100%" mt="4px">
                                        <Flex width="48%" justifyContent="center" mr="24px">
                                            <Input
                                                name="new_district"
                                                placeholder="Nome do Bairro"
                                                size="sm"
                                                mb="0px"
                                            />
                                        </Flex>

                                        <Button
                                            type="button"
                                            backgroundColor="green.500"
                                            borderRadius="0.125rem"
                                            width="40px"
                                            maxWidth="40px"
                                            p="0px"
                                            fontSize="16px"
                                            height="32px"
                                            title="Excluir Categorias"
                                            _hover={{
                                                backgroundColor: 'green.500'
                                            }}
                                            _focus={{
                                                outline: 'none'
                                            }}
                                            onClick={() => handleAddNewDistrict()}
                                        >
                                            <FiPlus size={20} />
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>
                        <Divider my="0px" />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default DeliveryDistrictSettings;
