import styled, { css } from 'styled-components';

import theme from '../../../styles/theme';

interface IProps {
  isErrored: boolean;
}

export const Container = styled.div<IProps>`
  .DayPicker-Day--disabled {
    background: transparent !important;
  }
  .DayPicker-Weekday {
    font-weight: 500;
    font-size: 16px;
    margin: 8px;
  }
  .DayPicker-Months {
    padding: 8px !important;
    ${props =>
        props.isErrored &&
      css`
        border: 2px solid ${theme.colors.red[500]};
      `}
  }
  .DayPicker-Month {
    width: 100% !important;
    margin: 0px !important;
  }
  .DayPicker {
    width: 100%;
    background: #fff;
  }
  .DayPicker-Day--selected {
    background: ${theme.colors.green[500]} !important;
    border-radius: 5px;
    color: ${theme.colors.white} !important;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${theme.colors.green[500]} !important;
    border-radius: 5px !important;
    color: ${theme.colors.white} !important;
  }
`;
