import CurrencyUtils from '../utils/CurrencyUtils';

export default class ProductHelper {
    static getCorrectPrice(product) {
        let correctPrice = null;

        if (product.is_promotional) {
            correctPrice = CurrencyUtils.numberToCurrency(product.price_promotional);
        } else {
            correctPrice = CurrencyUtils.numberToCurrency(product.price_sale);
        }

        return correctPrice;
    }
}
