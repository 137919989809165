import React from 'react';

import { Flex, Text } from '@chakra-ui/core';

const ListEmpty: React.FC = () => {
    return (
        <Flex width="100%" py="24px">
            <Text color="gray.700">Nenhum resultado encontrado</Text>
        </Flex>
    );
};

export default ListEmpty;
