import React, { useEffect, useRef } from 'react';

import { Button, Divider, Flex, Text } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Form from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';
import Select from '../../../../../components/Form/Select';
import { useLayout } from '../../../../../layouts/default';

const PaypalSettings: React.FC = () => {
    const { changeTitle } = useLayout();

    const formRef = useRef<FormHandles>(null);

    useEffect(() => {
        changeTitle('Configurarações de Cartão de Crédito');
    }, [changeTitle]);

    return (
        <Form ref={formRef as any} onSubmit={() => null}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="white"
                        px="24px"
                    >
                        <Flex
                            width="100%"
                            py="16px"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex
                                    width="100%"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Text mr="16px" fontSize="24px" color="purple.500">
                    Configurarações do Paypal
                                    </Text>
                                </Flex>

                                <Flex width="100%">
                                    <Form
                                        onSubmit={() => undefined}
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <Flex width="100%" mt="24px">
                                            <Flex width="48%" flexDirection="column">
                                                <Text width="100%" fontSize="20px">
                          Parcelamento
                                                </Text>

                                                <Select
                                                    width="200px"
                                                    name="max_installments"
                                                    label="Número máximo de parcelas"
                                                    size="sm"
                                                >
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                    <option>8</option>
                                                    <option>9</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                </Select>

                                                <Flex width="100%" flexWrap="wrap">
                                                    <Text width="100%">Taxas de Parcelamento</Text>

                                                    <Flex width="50%" flexDirection="column">
                                                        <Flex width="160px" mt="16px">
                                                            <Input
                                                                name="num_1"
                                                                label="Parcela 1"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 3"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 5"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 7"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 9"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 11"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>
                                                    </Flex>

                                                    <Flex width="50%" flexDirection="column">
                                                        <Flex width="160px" mt="16px">
                                                            <Input
                                                                name="num_1"
                                                                label="Parcela 2"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 4"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 6"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 8"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 10"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>

                                                        <Flex width="160px" mt="8px">
                                                            <Input
                                                                name="num_2"
                                                                label="Parcela 12"
                                                                defaultValue="0"
                                                                textAlign="right"
                                                                mb="0px"
                                                                size="sm"
                                                                badge="porcentage"
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            <Flex width="48%" flexDirection="column">
                                                <Flex width="100%">
                                                    <Text fontSize="20px">Dados Bancários</Text>
                                                </Flex>

                                                <Flex width="100%" justifyContent="space-between">
                                                    <Flex width="48%" flexDirection="column">
                                                        <Input
                                                            name="bank_code"
                                                            label="Código do Banco"
                                                            size="sm"
                                                        />

                                                        <Input
                                                            name="bank_code"
                                                            label="Número da Conta"
                                                            size="sm"
                                                        />

                                                        <Input
                                                            name="bank_code"
                                                            label="Agência"
                                                            mb="0px"
                                                            size="sm"
                                                        />
                                                    </Flex>

                                                    <Flex width="48%" flexDirection="column">
                                                        <Select
                                                            name="account_type"
                                                            label="Tipo de Conta"
                                                            size="sm"
                                                        >
                                                            <option>Conta Corrente</option>
                                                            <option>Conta Poupança</option>
                                                        </Select>
                                                        <Input
                                                            name="bank_code"
                                                            label="Conta DV"
                                                            size="sm"
                                                        />

                                                        <Input
                                                            name="bank_code"
                                                            label="Agência DV"
                                                            mb="0px"
                                                            size="sm"
                                                        />
                                                    </Flex>
                                                </Flex>

                                                <Flex width="100%" mt="80px">
                                                    <Input name="key_live" label="Chave Paypal" />
                                                </Flex>

                                                <Flex width="100%">
                                                    <Button
                                                        type="submit"
                                                        width="100%"
                                                        backgroundColor="purple.500"
                                                        height="48px"
                                                        mt="24px"
                                                        color="white"
                                                        _hover={{
                                                            backgroundColor: 'green.500'
                                                        }}
                                                        fontSize="24px"
                                                        fontWeight="400"
                                                        borderRadius="2px"
                                                    >
                            Confirmar
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Form>
                                </Flex>
                            </Flex>
                            <Divider my="0px" />
                        </Flex>
                        <Divider my="0px" />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default PaypalSettings;
