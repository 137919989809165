import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Divider, Flex, Text, useToast, Box } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Form from '../../../../components/Form';
import Checkbox from '../../../../components/Form/Checkbox';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import apiGateway from '../../../../shared/services/apiGateway';

const MenuSettingsEditPage: React.FC = () => {
    const { changeTitle } = useLayout();

    const formRef = useRef<FormHandles>(null);
    const { id } = useParams() as any;

    const toast = useToast();
    const navigate = useNavigate();

    const [menuSetting, setMenuSetting] = useState(null);

    const handleSubmit = useCallback(async () => {
        try {
            formRef.current.setErrors({});

            const response = await apiGateway.put(`/settings/menu_settings/${id}`, {
                show_document: menuSetting.show_document,
                show_whatsapp: menuSetting.show_whatsapp,
                show_telephone: menuSetting.show_telephone,
                show_email: menuSetting.show_email,
                show_address: menuSetting.show_address
            });

            if (response.status === HTTP.STATUS.SUCCESS) {
                toast({
                    title: 'Registro Atualizado',
                    description: 'Menu atualizado com sucesso',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                FormHelper.showErrors(formRef, error, toast);
            }
        }
    }, [id, menuSetting, toast]);

    useEffect(() => {
        changeTitle('Personalizar Menus');

        apiGateway.get(`/settings/menu_settings/${id}`).then(response => {
            setMenuSetting(response.data);
        });
    }, [changeTitle, id]);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex width="100%" flexDirection="column">
                {menuSetting !== null && (
                    <Flex width="100%" flexDirection="column" alignItems="center">
                        <Flex
                            width="100%"
                            maxWidth="800px"
                            flexDirection="column"
                            backgroundColor="white"
                            px="24px"
                        >
                            <Flex
                                width="100%"
                                py="16px"
                                justifyContent="space-between"
                                flexWrap="wrap"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                >
                                    <Flex
                                        width="100%"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        mb="16px"
                                    >
                                        <Text mr="16px" fontSize="24px" color="purple.500">
                                            {menuSetting.type === 'HEADER'
                                                ? 'Menu de Cabeçalho'
                                                : 'Menu de Rodapé'}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        mb="16px"
                                        px="16px"
                                        py="8px"
                                        backgroundColor="yellow.200"
                                        border="1px solid"
                                        borderColor="yellow.500"
                                        color="gray.800"
                                        flexWrap="nowrap"
                                        fontSize="14px"
                                    >
                                        <Text mr="8px">
                      Atenção, certifique-se que seus dados foram informados
                      corretamente na seção
                                        </Text>

                                        <Text
                                            textDecoration="underline"
                                            cursor="pointer"
                                            onClick={() =>
                                                navigate('/general_settings/informations')
                                            }
                                        >
                      INFORMAÇÕES
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex width="100%" justifyContent="space-between">
                                            <Box fontWeight="500">
                                                <Text fontSize="14px">Mostrar CNPJ?</Text>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    maxWidth="80px"
                                                    alignItems="flex-end"
                                                    mt="8px"
                                                >
                                                    <Checkbox
                                                        name="show_document"
                                                        isChecked={menuSetting.show_document === true}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_document: !oldState.show_document
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">SIm</Text>
                                                    </Checkbox>
                                                    <Checkbox
                                                        name="show_document"
                                                        isChecked={menuSetting.show_document === false}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_document: !oldState.show_document
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">Não</Text>
                                                    </Checkbox>
                                                </Flex>
                                            </Box>
                                            <Box fontWeight="500">
                                                <Text fontSize="14px">Mostrar Telephone?</Text>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    maxWidth="80px"
                                                    alignItems="flex-end"
                                                    mt="8px"
                                                >
                                                    <Checkbox
                                                        name="is_promotional"
                                                        isChecked={menuSetting.show_telephone === true}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_telephone: !oldState.show_telephone
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">SIm</Text>
                                                    </Checkbox>
                                                    <Checkbox
                                                        name="is_promotional"
                                                        isChecked={menuSetting.show_telephone === false}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_telephone: !oldState.show_telephone
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">Não</Text>
                                                    </Checkbox>
                                                </Flex>
                                            </Box>

                                            <Box fontWeight="500">
                                                <Text fontSize="14px">Mostrar Whatsapp?</Text>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    maxWidth="80px"
                                                    alignItems="flex-end"
                                                    mt="8px"
                                                >
                                                    <Checkbox
                                                        name="show_whatsapp"
                                                        isChecked={menuSetting.show_whatsapp === true}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_whatsapp: !oldState.show_whatsapp
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">SIm</Text>
                                                    </Checkbox>
                                                    <Checkbox
                                                        name="show_whatsapp"
                                                        isChecked={menuSetting.show_whatsapp === false}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_whatsapp: !oldState.show_whatsapp
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">Não</Text>
                                                    </Checkbox>
                                                </Flex>
                                            </Box>

                                            <Box fontWeight="500">
                                                <Text fontSize="14px">Mostrar Email?</Text>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    maxWidth="80px"
                                                    alignItems="flex-end"
                                                    mt="8px"
                                                >
                                                    <Checkbox
                                                        name="show_email"
                                                        isChecked={menuSetting.show_email === true}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_email: !oldState.show_email
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">SIm</Text>
                                                    </Checkbox>
                                                    <Checkbox
                                                        name="show_email"
                                                        isChecked={menuSetting.show_email === false}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_email: !oldState.show_email
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">Não</Text>
                                                    </Checkbox>
                                                </Flex>
                                            </Box>

                                            <Box fontWeight="500">
                                                <Text fontSize="14px">Mostrar Endereço?</Text>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    maxWidth="80px"
                                                    alignItems="flex-end"
                                                    mt="8px"
                                                >
                                                    <Checkbox
                                                        name="show_address"
                                                        isChecked={menuSetting.show_address === true}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_address: !oldState.show_address
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">SIm</Text>
                                                    </Checkbox>
                                                    <Checkbox
                                                        name="show_address"
                                                        isChecked={menuSetting.show_address === false}
                                                        onChange={() =>
                                                            setMenuSetting(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    show_address: !oldState.show_address
                                                                };
                                                            })
                                                        }
                                                        value="0"
                                                        mr="16px"
                                                    >
                                                        <Text fontSize="14px">Não</Text>
                                                    </Checkbox>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%">
                                        <Flex width="100%" mt="24px">
                                            <Flex width="100%">
                                                <Button
                                                    type="submit"
                                                    width="100%"
                                                    backgroundColor="purple.500"
                                                    height="48px"
                                                    mt="24px"
                                                    color="white"
                                                    _hover={{
                                                        backgroundColor: 'green.500'
                                                    }}
                                                    fontSize="24px"
                                                    fontWeight="400"
                                                    borderRadius="2px"
                                                >
                          Confirmar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Divider my="0px" />
                            </Flex>
                            <Divider my="0px" />
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Form>
    );
};

export default MenuSettingsEditPage;
