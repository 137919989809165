import { FormHandles } from '@unform/core';

import apiGateway from '../services/apiGateway';
import ValidationUtils from '../utils/ValidationUtils';

export default class FormHelper {
    static showErrors(
        formRef: React.MutableRefObject<FormHandles>,
        error,
        toast
    ) {
        const newErrors = ValidationUtils.getValidationErrors(error);

        formRef.current.setErrors(newErrors);

        const firstInputWithErrorRef = formRef.current.getFieldRef(
            error.inner[0].path
        );

        if (firstInputWithErrorRef) {
            firstInputWithErrorRef.focus();
        }

        toast({
            title: 'Dados incompletos',
            description:
        error.errors.length > 1
            ? 'Preencha os campos obrigatórios'
            : error.errors[0],
            status: 'error',
            duration: 4000,
            isClosable: true
        });
    }

    static reset(formRef: React.MutableRefObject<FormHandles>) {
        formRef.current.setErrors({});
        formRef.current.reset();
    }

    static async searchResultMultipleSelect(
        query,
        url,
        is_name = false,
        limitCharacter = 3
    ) {
        if (query.length >= limitCharacter) {
            const response = await apiGateway.get(`${url}?query=${query}`);

            if (response.data) {
                return response.data.map(data => ({
                    id: data.id,
                    text: is_name ? data.name : data.value,
                    value: data.id
                }));
            }

            return {};
        }

        return {};
    }

    static onSuccess(title: string, description: string, toast) {
        toast({
            title,
            description,
            status: 'success',
            duration: 4000,
            isClosable: true
        });
    }

    static onError(title: string, description: string, toast) {
        toast({
            title,
            description,
            status: 'error',
            duration: 4000,
            isClosable: true
        });
    }
}
