/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';

import SelectMultiple from '../../../../components/Form/SelectMultiple';
import { useProductForm } from '../../contexts/ProductFormProvider';
import ProductFormHelper from '../../helpers/ProductFormHelper';

interface IProps {
  defaultMultiSelect?: string[];
}

const SelectCategories = ({ defaultMultiSelect = [] }: IProps) => {
    const { addCategories } = useProductForm();

    const handleSetCategories = useCallback(
        (ids: string[]): void => {
            addCategories(ids);
        },
        [addCategories]
    );

    const handleOnSearchCategories = useCallback(
        async (query: string): Promise<any> => {
            return ProductFormHelper.searchResultMultipleSelect(
                query,
                '/categories',
                true,
                0
            );
        },
        []
    );

    return (
        <SelectMultiple
            name="Categories"
            label="Adicionar categorias"
            size="sm"
            placeholder="Pesquisar categorias"
            onSearch={handleOnSearchCategories}
            onSetItems={handleSetCategories}
            defaultValues={defaultMultiSelect}
            enterPress={false}
        />
    );
};

export default SelectCategories;
