import React from 'react';

import { Flex, Text } from '@chakra-ui/core';

interface ICircleBadgeProp {
  children: any;
  num: number;
}

const CircleBadge: React.FC<ICircleBadgeProp> = ({ children, num = 0 }) => {
    return (
        <Flex position="relative" flexDirection="column" zIndex={1000000}>
            {children}
            <Flex
                position="absolute"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="pink.500"
                width="20px"
                height="20px"
                color="white"
                top="-8px"
                right="-8px"
            >
                <Text fontSize="14px">{num}</Text>
            </Flex>
        </Flex>
    );
};

export default CircleBadge;
