import NumberUtils from '../../../shared/utils/NumberUtils';

export default class ProductMap {
    static formDataToCreateDTO(data): any {
        return {
            code_sku: data.code_sku,
            name: data.name,
            slug: data.slug,
            description: data.description,
            is_enable: data.is_enable,
            is_promotional: data.is_promotional,
            is_free_shipping: data.is_free_shipping,
            extra_shipping_value: NumberUtils.formatNumber(data.extra_shipping_value),
            marcap: NumberUtils.formatNumber(data.marcap),
            price_sale: NumberUtils.formatNumber(data.price_sale),
            price_purchase: NumberUtils.formatNumber(data.price_purchase),
            price_promotional: NumberUtils.formatNumber(data.price_promotional),
            title: data.title,
            meta_description: data.meta_description,
            width: data.width,
            height: data.height,
            tags: data.tags,
            stock: data.stock,
            images: data.images.map(image => ({
                id: image.id,
                alt: image.alt,
                is_cover: image.is_cover
            })),
            categories_ids: data.categories,
            types: data.types,
            subtypes: data.subtypes,
            cover_id: data.cover_id
        };
    }
}
