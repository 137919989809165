import React, { useRef, useEffect } from 'react';

import {
    Flex,
    Text,
    Textarea as ChakraTextArea,
    InputProps
} from '@chakra-ui/core';
import { useField } from '@unform/core';

import InputBadgeError from '../InputBadgeError';

interface IProps extends InputProps<HTMLTextAreaElement> {
  name: string;
  label?: string;
  isRequired?: boolean;
  formatChars?: {
    [key: string]: string;
  };
  badgeErrorColor?: string;
  mb?: string | number;
}

const TextArea: React.FC<IProps> = ({
    name,
    label,
    isRequired = false,
    badgeErrorColor = 'red',
    mb = '16px',
    ...rest
}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { fieldName, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    return (
        <Flex width="100%" flexDirection="column" fontWeight="500" mb={mb}>
            {label && (
                <Flex width="100%" textAlign="center" mb="4px">
                    <Text>{label}</Text>
                    {isRequired && (
                        <Text ml="8px" color="red.500">
              *
                        </Text>
                    )}
                </Flex>
            )}

            <Flex
                width="100%"
                flexDirection="column"
                position="relative"
                flexWrap="wrap"
            >
                <ChakraTextArea
                    px="12px"
                    ref={inputRef}
                    name={name}
                    isInvalid={!!error}
                    errorBorderColor="red.500"
                    whiteSpace="pre-line"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            inputRef.current.value += '\r\n';
                        }
                    }}
                    {...rest}
                />
                {error && <InputBadgeError error={error} color={badgeErrorColor} />}
            </Flex>
        </Flex>
    );
};

export default TextArea;
