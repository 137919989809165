/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';

import SelectMultiple from '../../../../components/Form/SelectMultiple';
import { useProductForm } from '../../contexts/ProductFormProvider';
import ProductFormHelper from '../../helpers/ProductFormHelper';

interface IProps {
  defaultMultiSelect?: any[];
}

const SelectTags = ({ defaultMultiSelect }: IProps) => {
    const { addTags } = useProductForm();

    const handleSetTags = useCallback(
        (ids: string[], news: string[]): void => {
            addTags(ids, news);
        },
        [addTags]
    );

    const handleOnSearchTags = useCallback(async (query: string): Promise<
    any
  > => {
        return ProductFormHelper.searchResultMultipleSelect(
            query,
            '/products/tags',
            false,
            0
        );
    }, []);

    return (
        <SelectMultiple
            name="tags"
            label="Adicionar tags"
            size="sm"
            placeholder="Pesquise ou adicione novas tags"
            onSearch={handleOnSearchTags}
            onSetItems={handleSetTags}
            showAddButton
            defaultValues={defaultMultiSelect}
        />
    );
};

export default SelectTags;
