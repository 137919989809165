import React, { useCallback } from 'react';
import { FiAlertTriangle, FiTrash2 } from 'react-icons/fi';

import { Box, Collapse, Divider, Flex, Image, Text } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Input from '../../../../../../components/Form/Input';
import CurrencyUtils from '../../../../../../shared/utils/CurrencyUtils';
import CollapseCheckoutBox from '../../../../components/CollapseCheckoutBox';
import { useCashier } from '../../../../hooks/useCashier';

interface IProps {
  products: any[];
  formRef: React.MutableRefObject<FormHandles>;
}

const ProductsSection: React.FC<IProps> = ({ formRef, products }) => {
    const { getStateBox, collapseBox, addProduct, removeProduct } = useCashier();

    const handleFocus = useCallback(() => {
        formRef.current?.getFieldRef('search').focus();
    }, []);

    return (
        <Flex width="100%" flexDirection={'column'}>
            <CollapseCheckoutBox
                title="Produtos"
                isCollapsed={getStateBox('PRODUCTS')}
                onCollapse={() => collapseBox('PRODUCTS')}
                isErrored={products.length <= 0}
                isComplete={products.length > 0}
            />

            {products.length <= 0 && (
                <Flex width={'100%'} justifyContent="center" alignContent={'center'}>
                    <Flex
                        fontSize="12px"
                        backgroundColor={'green.500'}
                        px="8px"
                        py="4px"
                        cursor={'pointer'}
                        color="white"
                        borderRadius={'2px'}
                        onClick={() => handleFocus()}
                    >
            Adicionar produtos
                    </Flex>
                </Flex>
            )}

            {products.length > 0 && (
                <Collapse
                    mt="4px"
                    isOpen={getStateBox('PRODUCTS')}
                    display="flex"
                    flexDirection="column"
                    pt="8px"
                >
                    <Flex
                        height="100%"
                        justifyContent="space-between"
                        fontSize="12px"
                        fontWeight="600"
                    >
                        <Text width="32px">#</Text>
                        <Text width="160px">Item</Text>
                        <Text width="100%" maxWidth="80px">
              Valor
                        </Text>
                        <Text width="100%" maxWidth="32px">
              Qtd
                        </Text>

                        <Text width="100%" maxWidth="24px" />
                    </Flex>
                    <Divider my="4px" />
                    {products.map(product => (
                        <Flex
                            key={product.id}
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            fontSize="12px"
                            color="gray.700"
                            height="56px"
                            py="8px"
                        >
                            <Box width="32px" height="32px" mr="8px">
                                <Image src={product.url_thumb} />
                            </Box>

                            <Flex
                                width="160px"
                                flexWrap="wrap"
                                alignItems="center"
                                height="100%"
                                overflow="hidden"
                                mr="8px"
                                color="gray.800"
                            >
                                <Text>{product.name}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                maxWidth="80px"
                                flexWrap="wrap"
                                alignItems="center"
                                height="100%"
                            >
                                <Text overflowWrap="break-word" color="green.500">
                                    {CurrencyUtils.numberToCurrency(product.price)}
                                </Text>
                            </Flex>

                            <Flex alignItems="center" width="100%" maxWidth="32px" mr="8px">
                                <Flex height="100%" alignItems="center">
                                    <Input
                                        name="quantity"
                                        backgroundColor="gray.500"
                                        borderRadius="2px"
                                        width="48px"
                                        px="4px"
                                        textAlign="center"
                                        size="sm"
                                        mb="0px"
                                        color="purple.500"
                                        defaultValue={product.quantity}
                                        onChange={e => addProduct(product, e.currentTarget.value)}
                                    />
                                </Flex>
                            </Flex>
                            <Flex
                                width="24px"
                                height="24px"
                                backgroundColor="pink.500"
                                justifyContent="center"
                                color="white"
                                px="4px"
                                alignItems="center"
                                cursor="pointer"
                                onClick={() => removeProduct(product.id)}
                            >
                                <FiTrash2 size={16} />
                            </Flex>
                        </Flex>
                    ))}
                </Collapse>
            )}
        </Flex>
    );
};

export default ProductsSection;
