import React, { useCallback } from 'react';

import {
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Flex,
    Button
} from '@chakra-ui/core';

interface IProps {
  deleteProducts: any;
  isOpen: any;
  onClose: any;
}

const ModalDeleteProduct: React.FC<IProps> = ({
    deleteProducts,
    isOpen,
    onClose
}) => {
    const handleDeleteProducts = useCallback(async () => {
        await deleteProducts();
    }, [deleteProducts]);

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                initialFocusRef={null}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="500">Confirmar Exclusão</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb="8px">
                        <Flex width="100%" flexDirection="column">
                            <Text fontSize="18px">
                Deseja remover os produtos selecionados?
                            </Text>
                        </Flex>

                        <Flex
                            width="100%"
                            flexDirection="row"
                            justifyContent="space-between"
                            mt="16px"
                            mb="8px"
                            fontWeight="500"
                        >
                            <Button
                                width="48%"
                                backgroundColor="red.500"
                                onClick={() => handleDeleteProducts()}
                                color="white"
                                variantColor="red"
                            >
                                Sim
                            </Button>
                            <Button
                                width="48%"
                                backgroundColor="white"
                                color="red.500"
                                onClick={() => onClose()}
                            >
                                Cancelar
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalDeleteProduct;
